<template lang="html">
  <div class="QuestionsGrid">
    <div class="QuestionsGrid--Question" :class="{'active':selected == q.name}" v-for="q in questionTypes" v-on:click="selectQuestionType(q)">
      <div class="QuestionsGrid--QuestionElement">
        <QuestionIMG :name="q.name"/>
        <p>{{q.text}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue';
import QuestionIMG from '../Question/QuestionIMG.vue'
export default defineComponent({
  name: 'QuestionsGrid',
  components:{
    QuestionIMG
  },
  props:{
    directSelection:{
      type: Boolean,
      default: false
    },
    isPollActivity:{
      type:Boolean,
      default:false,
    }
  },
  data(){
    return{
      selected:'',
      questionTypes:[]
    }
  },
  beforeMount(){
    if (!this.isPollActivity) {
        this.questionTypes = [
            { type: 'RATING_FACES', name: 'RATING_FACES', text: this.$t('activity_survey_rating_faces') },
            { type: 'SELECTION', name: 'SELECTION', text: this.$t('activity_survey_selection') },
            { type: 'MULTISELECTION', name: 'MULTISELECTION', text: this.$t('activity_survey_multi_select') },
            { type: 'RANKING', name: 'RANKING-STARS', text: this.$t('activity_survey_ranking_stars') },
            { type: 'RANKING', name: 'RANKING-NUMBERS', text: this.$t('activity_survey_ranking_numbers') },
            { type: 'SORTING', name: 'SORTING', text: this.$t('activity_survey_order') },
            { type: 'DIVIDE_POINTS', name: 'DIVIDE_POINTS', text: this.$t('activity_survey_divide_points_distribution') },
            { type: 'IMAGE_CHOICE', name: 'IMAGE_CHOICE', text: this.$t('activity_survey_image_choice') },
            { type: 'MULTIPLEIMAGE_CHOICE', name: 'MULTIPLEIMAGE_CHOICE', text: this.$t('activity_survey_image_multiple') },
            { type: 'IMAGE_URL', name: 'IMAGE_URL', text: this.$t('activity_survey_image_upload') },
            { type: 'DATE', name: 'DATE', text: this.$t('activity_survey_date') },
            { type: 'MATRIX', name: 'MATRIX', text: this.$t('activity_survey_matrix') },
            { type: 'MATRIX', name: 'matrix-options', text: this.$t('activity_survey_matrix_options') },
            { type: 'DECIMAL_NUMBER', name: 'NUMBER', text: this.$t('activity_survey_number') },
            { type: 'MULTINUMBER', name: 'MULTINUMBER', text: this.$t('activity_survey_multi_number') },
            { type: 'TEXT', name: 'TEXT', text: this.$t('activity_survey_text') },
            { type: 'TEXTAREA', name: 'TEXTAREA', text: this.$t('activity_survey_textarea') },
            { type: 'MULTITEXT', name: 'MULTITEXT', text: this.$t('activity_survey_multitext') },
            { type: 'LABEL', name: 'LABEL', text: this.$t('activity_survey_label') }
        ]
    }else{
        this.questionTypes = [
            { type: 'SELECTION', name: 'SELECTION', text: this.$t('activity_survey_selection') },
            { type: 'MULTISELECTION', name: 'MULTISELECTION', text: this.$t('activity_survey_multi_select') },
            { type: 'RANKING', name: 'RANKING-NUMBERS', text: this.$t('activity_survey_ranking_numbers') }
        ]
    }
  },
  methods:{
    selectQuestionType(type){
      if(!this.directSelection) this.selected = type.name
      this.$emit('select-type', type)
    }
  }
})
</script>
<style lang="scss" scoped>
  .QuestionsGrid{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(178px, 1fr));
    grid-gap: 18px;
    p{
      font-weight: bold;
      font-size: 13px;
      letter-spacing: 0.01em;
      text-transform: uppercase;
      color: #3E3E3E;
      font-family: Roboto Condensed;
    }
    &--Question{
      padding: 2px;
      &.active{
        background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
        .QuestionsGrid--QuestionElement{
          background:#FFF5F5;
        }
      }
    }
    &--QuestionElement{
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      height: 97px;
      cursor: pointer;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      gap: 16px;
      padding-bottom: 7px;
    }
  }
</style>
