<template>
<div>
  <svg v-if="name=='RATING_FACES'" width="102" height="18" viewBox="0 0 102 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.5455 17.2281C27.7518 16.9571 26.9388 16.7636 26.1839 16.4151C22.8931 14.8859 20.8412 11.3048 21.2283 7.83977C21.6736 3.91021 24.3836 0.92916 28.197 0.154861C28.3906 0.116146 28.5648 0.0387166 28.739 -0.0193558C29.4553 -0.0193558 30.1521 -0.0193558 30.8684 -0.0193558C31.7588 0.271006 32.688 0.464581 33.5203 0.85173C36.8692 2.41968 38.7468 5.84595 38.3984 9.54323C38.0693 13.0276 35.4561 16.028 31.9717 16.9765C31.7394 17.0346 31.5072 17.1313 31.2555 17.2281C30.3457 17.2281 29.4553 17.2281 28.5455 17.2281ZM29.8037 16.299C34.0623 16.299 37.4886 12.8727 37.4886 8.61407C37.4886 4.39414 34.0817 0.967874 29.8811 0.948517C25.6225 0.92916 22.1575 4.33607 22.1575 8.59471C22.1381 12.8534 25.5644 16.299 29.8037 16.299Z" fill="#BDBDBD"/>
    <path d="M52.5485 0C53.0905 0.174217 53.6519 0.329077 54.1939 0.522651C58.1622 1.97446 60.3689 6.03953 59.5172 10.2014C58.6848 14.2664 54.9101 17.0926 50.6515 16.8216C46.6638 16.5893 43.2763 13.2792 42.8891 9.25287C42.4826 5.09101 45.212 1.23888 49.2965 0.212932C49.5481 0.15486 49.7804 0.0580724 50.032 0C50.8644 0 51.7161 0 52.5485 0ZM51.3096 0.929158C47.2058 0.909801 43.857 4.20057 43.8183 8.30435C43.7796 12.4856 47.051 15.8538 51.1935 15.9118C55.3747 15.9505 58.7429 12.6791 58.7816 8.53664C58.8203 4.31671 55.5295 0.967873 51.3096 0.929158Z" fill="#BDBDBD"/>
    <path d="M101.91 10.0659C101.388 11.9242 100.633 13.6276 99.1034 14.9052C96.3546 17.2281 92.5606 17.4991 89.5795 15.5828C86.6372 13.6857 85.2241 10.0078 86.1339 6.63961C87.0824 3.13591 90.1409 0.696869 93.6639 0.600081C97.1676 0.503294 100.362 2.7294 101.504 6.0976C101.639 6.52346 101.775 6.94933 101.93 7.37519C101.91 8.26563 101.91 9.15608 101.91 10.0659ZM100.962 8.74957C100.981 4.82001 97.8451 1.58731 93.993 1.5486C90.0634 1.50988 86.8501 4.6845 86.8114 8.63342C86.792 12.6211 89.9086 15.8538 93.8188 15.8731C97.7484 15.9118 100.942 12.7178 100.962 8.74957Z" fill="#BDBDBD"/>
    <path d="M0.0898438 7.54941C0.264061 7.0074 0.41892 6.44603 0.612495 5.90403C1.98687 2.09061 5.97451 -0.096788 9.90407 0.793655C13.9304 1.70346 16.6211 5.61366 16.0985 9.81423C15.6339 13.7438 12.2076 16.8023 8.25869 16.841C4.60013 16.8604 1.5223 14.5762 0.457635 11.0725C0.341491 10.666 0.205988 10.2788 0.0898438 9.87231C0.0898438 9.09801 0.0898438 8.32371 0.0898438 7.54941ZM0.999644 8.73022C0.999644 12.7179 4.19362 15.9699 8.12319 15.9699C12.0528 15.9893 15.2661 12.7566 15.3048 8.74957C15.3435 4.76194 12.1108 1.49052 8.10383 1.50988C4.13555 1.50988 0.999644 4.70386 0.999644 8.73022Z" fill="#BDBDBD"/>
    <path d="M80.8106 8.71086C80.8106 13.2599 77.2682 16.841 72.7773 16.841C68.3637 16.841 64.7826 13.2018 64.7633 8.74957C64.7439 4.25864 68.4025 0.580727 72.816 0.580727C77.2295 0.600084 80.7912 4.23929 80.8106 8.71086ZM72.7773 1.50988C68.809 1.50988 65.6537 4.70387 65.6537 8.73022C65.6537 12.7179 68.8283 15.9699 72.7773 15.9893C76.7262 16.028 80.0169 12.6985 79.9782 8.6915C79.9202 4.68451 76.7455 1.49053 72.7773 1.50988Z" fill="url(#paint0_linear)"/>
    <path d="M34.6813 11.808C34.4297 11.9242 34.1974 12.0597 33.9458 12.1758C31.7777 8.47857 27.4417 8.96251 25.7188 12.1758C25.4865 12.0791 25.2349 11.9629 25.0413 11.8661C24.8284 11.3048 26.5125 9.6013 27.6933 9.07865C30.384 7.9172 33.307 9.03994 34.6813 11.808Z" fill="#BDBDBD"/>
    <path d="M34.5652 5.30395C33.5393 4.85873 32.6295 4.47158 31.6616 4.04571C31.7584 3.77471 31.8552 3.54242 31.9713 3.19398C32.9586 3.60049 33.8877 4.007 34.875 4.4135C34.7588 4.70387 34.6814 4.93616 34.5652 5.30395Z" fill="#BDBDBD"/>
    <path d="M25.1383 5.28459C25.0028 5.01359 24.8866 4.80065 24.7124 4.47158C25.6803 4.04571 26.6094 3.63921 27.5967 3.19398C27.7515 3.46499 27.8677 3.69728 28.0419 4.007C27.0353 4.45222 26.1061 4.85873 25.1383 5.28459Z" fill="#BDBDBD"/>
    <path d="M34.0232 6.7364C33.4231 7.20098 33.0553 7.66556 32.7456 7.6462C32.4165 7.62684 31.8745 7.10419 31.8745 6.79447C31.8745 6.48475 32.3584 5.94274 32.6875 5.90403C32.9972 5.84595 33.3844 6.31053 34.0232 6.7364Z" fill="#BDBDBD"/>
    <path d="M27.1902 5.71045C27.4225 6.07824 27.8484 6.48475 27.7903 6.79447C27.7322 7.1429 27.2096 7.39455 26.8805 7.68491C26.5901 7.35584 26.0868 7.04612 26.0675 6.69768C26.0481 6.36861 26.5127 6.02017 26.7837 5.67174C26.9192 5.6911 27.0547 5.71045 27.1902 5.71045Z" fill="#BDBDBD"/>
    <path d="M46.9734 11.5758C46.7605 10.8595 47.0315 10.666 47.6703 10.6853C50.09 10.7047 52.4903 10.7047 54.91 10.6853C55.5681 10.6853 55.8004 10.8983 55.5875 11.5758C52.7419 11.5758 49.8964 11.5758 46.9734 11.5758Z" fill="#BDBDBD"/>
    <path d="M55.2006 6.77511C54.8522 6.98804 54.4844 7.4139 54.136 7.37519C53.8456 7.35583 53.3036 6.81382 53.3423 6.58153C53.4004 6.2331 53.8456 5.7298 54.1747 5.69109C54.4844 5.65237 54.8522 6.11695 55.2006 6.34924C55.2006 6.5041 55.2006 6.6396 55.2006 6.77511Z" fill="#BDBDBD"/>
    <path d="M48.2898 7.56877C48.0769 7.20098 47.6704 6.83319 47.7091 6.48475C47.7285 6.17503 48.2898 5.63302 48.5028 5.6911C48.8512 5.76853 49.3351 6.21375 49.3739 6.54283C49.4126 6.85254 48.948 7.22034 48.7157 7.56877C48.5802 7.56877 48.4447 7.56877 48.2898 7.56877Z" fill="#BDBDBD"/>
    <path d="M93.8769 9.07865C95.1352 9.07865 96.3934 9.11736 97.6516 9.05929C98.4259 9.03993 98.6969 9.33029 98.6001 10.0852C98.3291 12.5243 96.1998 14.3826 93.7608 14.3245C91.3798 14.2664 89.3473 12.292 89.1731 9.91102C89.1343 9.27222 89.4053 9.03993 90.0054 9.05929C91.3024 9.098 92.58 9.07865 93.8769 9.07865ZM97.5936 10.0465C96.316 10.0465 95.0964 10.0465 93.8769 10.0465C92.6768 10.0465 91.4572 10.0465 90.2377 10.0465C90.5087 11.9629 91.9218 13.2599 93.6833 13.3566C95.6191 13.4534 97.1096 12.2533 97.5936 10.0465Z" fill="#BDBDBD"/>
    <path d="M90.7213 5.88467C91.1472 6.07824 91.5924 6.19439 91.9409 6.42667C92.1151 6.54282 92.1151 6.89125 92.1925 7.1429C91.9409 7.16226 91.6892 7.20097 91.4376 7.22033C90.973 7.22033 90.5084 7.22033 90.0245 7.22033C89.7728 7.22033 89.5212 7.20097 89.2695 7.18161C89.3663 6.91061 89.3663 6.56218 89.5599 6.40732C89.889 6.17503 90.2955 6.05888 90.7213 5.88467Z" fill="#BDBDBD"/>
    <path d="M97.0514 5.86531C97.5354 6.0976 97.9806 6.25246 98.3484 6.52346C98.4839 6.62025 98.4645 6.94933 98.5226 7.18162C98.2903 7.22033 97.9999 7.37519 97.8644 7.27841C97.3224 6.89126 96.8385 6.89126 96.2771 7.25905C96.1416 7.35584 95.8319 7.22033 95.5996 7.18162C95.6383 6.94933 95.619 6.62025 95.7545 6.52346C96.1223 6.25246 96.5675 6.0976 97.0514 5.86531Z" fill="#BDBDBD"/>
    <path d="M12.6916 11.6532C12.227 12.0791 11.9366 12.0597 11.5882 11.537C9.78796 8.92379 6.4972 8.94315 4.65824 11.537C4.29044 12.0403 4.29044 12.0403 3.57422 11.6532C4.50338 9.83359 5.95519 8.76893 7.98772 8.71086C10.117 8.67214 11.6463 9.75616 12.6916 11.6532Z" fill="#BDBDBD"/>
    <path d="M10.7755 5.69109C11.2013 6.2331 11.6466 6.56218 11.6272 6.8719C11.6078 7.18162 11.1433 7.68491 10.8723 7.70427C10.5819 7.72363 10.0592 7.25905 10.0205 6.94933C9.98181 6.63961 10.4077 6.27182 10.7755 5.69109Z" fill="#BDBDBD"/>
    <path d="M5.4905 5.69109C5.87764 6.25246 6.30351 6.62025 6.26479 6.92997C6.22608 7.23969 5.72279 7.72363 5.45178 7.70427C5.14206 7.68492 4.65813 7.20098 4.63877 6.89126C4.61941 6.58154 5.08399 6.2331 5.4905 5.69109Z" fill="#BDBDBD"/>
    <path d="M10.9688 4.60707C10.4655 4.99422 10.059 5.28459 9.59443 5.63302C9.42021 5.40073 9.28471 5.20716 9.07178 4.93615C9.53636 4.58772 9.96222 4.278 10.4268 3.94892C10.6204 4.16185 10.7752 4.35543 10.9688 4.60707Z" fill="#BDBDBD"/>
    <path d="M6.72918 5.67173C6.18718 5.26523 5.78067 4.95551 5.33545 4.60707C5.50967 4.37479 5.68388 4.18121 5.8581 3.94892C6.30332 4.25864 6.67111 4.52964 7.1744 4.87808C7.01954 5.12973 6.88404 5.36201 6.72918 5.67173Z" fill="#BDBDBD"/>
    <path d="M68.46 10.6853C68.9826 10.724 69.3698 10.6272 69.544 10.7821C72.1959 12.9889 73.9381 12.9501 76.3191 10.5111C76.5127 10.6466 76.7256 10.7821 76.9385 10.9176C75.0802 13.9567 70.7635 13.9761 68.46 10.6853Z" fill="url(#paint1_linear)"/>
    <path d="M71.4024 6.98804C70.8217 7.35584 70.4346 7.76234 70.1442 7.70427C69.8345 7.6462 69.3699 7.1429 69.3893 6.8719C69.4086 6.58154 69.9119 6.11696 70.2216 6.0976C70.5313 6.07824 70.8411 6.54282 71.4024 6.98804Z" fill="url(#paint2_linear)"/>
    <path d="M76.7649 6.8719C76.2036 7.2784 75.8745 7.72362 75.5841 7.70427C75.255 7.66555 74.984 7.22033 74.6743 6.92997C74.9647 6.62025 75.2163 6.13631 75.5261 6.07824C75.8164 6.03953 76.1842 6.48475 76.7649 6.8719Z" fill="url(#paint3_linear)"/>
    <path d="M97.5931 10.0465C97.1092 12.2533 95.6187 13.4534 93.6829 13.3567C91.9214 13.2792 90.5083 11.9629 90.2373 10.0465C91.4568 10.0465 92.6763 10.0465 93.8765 10.0465C95.096 10.0465 96.3155 10.0465 97.5931 10.0465Z" fill="white"/>
    <defs>
    <linearGradient id="paint0_linear" x1="64.7632" y1="8.71086" x2="80.8106" y2="8.71086" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="68.46" y1="11.8435" x2="76.9385" y2="11.8435" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="69.3887" y1="6.90343" x2="71.4024" y2="6.90343" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="74.6743" y1="6.89038" x2="76.7649" y2="6.89038" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='SELECTION'" width="40" height="38" viewBox="0 0 40 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.43958 10.9236C2.43661 10.9236 0 8.46677 0 5.45519C0 2.44361 2.43661 0 5.43958 0C8.44254 0 10.8792 2.44361 10.8792 5.45519C10.8792 8.46677 8.44254 10.9236 5.43958 10.9236ZM5.43958 2.17943C3.63517 2.17943 2.1732 3.6456 2.1732 5.45519C2.1732 7.26478 3.63517 8.73095 5.43958 8.73095C7.24399 8.73095 8.70596 7.26478 8.70596 5.45519C8.70596 3.6456 7.24399 2.17943 5.43958 2.17943ZM40 4.08149H13.6319V7.4497H40V4.08149ZM5.43958 4.37208C4.83372 4.37208 4.34639 4.8608 4.34639 5.4684C4.34639 6.076 4.83372 6.56472 5.43958 6.56472C6.04544 6.56472 6.53276 6.076 6.53276 5.4684C6.53276 4.8608 6.04544 4.37208 5.43958 4.37208ZM5.43958 24.0134C2.43661 24.0134 0 21.5698 0 18.5582C0 15.5466 2.43661 13.103 5.43958 13.103C8.44254 13.103 10.8792 15.5466 10.8792 18.5582C10.8792 21.5698 8.44254 24.0134 5.43958 24.0134ZM5.43958 15.2825C3.63517 15.2825 2.1732 16.7486 2.1732 18.5582C2.1732 20.3678 3.63517 21.834 5.43958 21.834C7.24399 21.834 8.70596 20.3678 8.70596 18.5582C8.70596 16.7486 7.24399 15.2825 5.43958 15.2825ZM40 16.8675H13.6319V20.2357H40V16.8675Z" fill="url(#paint0_linear)"/>
    <path d="M5.43958 26.2193C2.43661 26.2193 0 28.6629 0 31.6745C0 34.686 2.43661 37.1296 5.43958 37.1296C8.44254 37.1296 10.8792 34.686 10.8792 31.6745C10.8792 28.6629 8.44254 26.2193 5.43958 26.2193ZM5.43958 34.9502C3.63517 34.9502 2.1732 33.484 2.1732 31.6745C2.1732 29.8649 3.63517 28.3987 5.43958 28.3987C7.24399 28.3987 8.70596 29.8649 8.70596 31.6745C8.70596 33.484 7.24399 34.9502 5.43958 34.9502Z" fill="url(#paint1_linear)"/>
    <path d="M39.9999 30.0762H13.6318V33.4444H39.9999V30.0762Z" fill="url(#paint2_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="1.49012e-07" y1="12.0067" x2="40" y2="12.0067" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="4.0528e-08" y1="31.6745" x2="10.8792" y2="31.6745" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="13.6318" y1="31.7603" x2="39.9999" y2="31.7603" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='MULTISELECTION'" width="40" height="37" viewBox="0 0 40 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40 3.63995H13.5972V7.00296H40V3.63995ZM40 16.4194H13.5972V19.7824H40V16.4194Z" fill="url(#paint0_linear)"/>
    <path d="M40 29.6077H13.5972V32.9707H40V29.6077Z" fill="url(#paint1_linear)"/>
    <path d="M8.00528 0H2.28157C1.0155 0 0 1.0155 0 2.28157V8.01846C0 9.27135 1.0155 10.2868 2.28157 10.2868H8.01846C9.27135 10.2868 10.2868 9.27135 10.2868 8.00528V2.28157C10.2868 1.0155 9.27135 0 8.00528 0ZM8.94164 8.00528C8.94164 8.51962 8.51962 8.94164 8.00528 8.94164H2.28157C1.76723 8.94164 1.3452 8.51962 1.3452 8.00528V2.28157C1.3452 1.76723 1.76723 1.3452 2.28157 1.3452H8.01846C8.53281 1.3452 8.95483 1.76723 8.95483 2.28157V8.00528H8.94164Z" fill="url(#paint2_linear)"/>
    <path d="M4.90604 7.45138C4.77415 7.58326 4.58952 7.66239 4.39169 7.66239C4.19387 7.66239 4.00923 7.58326 3.87735 7.45138L2.28157 5.8556C1.99143 5.56546 1.99143 5.11706 2.28157 4.82691C2.57171 4.53677 3.02011 4.53677 3.31026 4.82691L4.25981 5.77647C4.32575 5.84241 4.44445 5.84241 4.52358 5.77647L7.09529 3.20476C7.38543 2.91462 7.83383 2.91462 8.12397 3.20476C8.41411 3.4949 8.41411 3.9433 8.12397 4.23344L4.90604 7.45138Z" fill="url(#paint3_linear)"/>
    <path d="M8.00528 12.9245H2.28157C1.0155 12.9245 0 13.94 0 15.2061V20.943C0 22.1958 1.0155 23.2245 2.28157 23.2245H8.01846C9.27135 23.2245 10.3 22.209 10.3 20.943V15.2061C10.2868 13.94 9.27135 12.9245 8.00528 12.9245ZM8.94164 20.9298C8.94164 21.4441 8.51962 21.8661 8.00528 21.8661H2.28157C1.76723 21.8661 1.3452 21.4441 1.3452 20.9298V15.1929C1.3452 14.6785 1.76723 14.2565 2.28157 14.2565H8.01846C8.53281 14.2565 8.95483 14.6785 8.95483 15.1929V20.9298H8.94164Z" fill="url(#paint4_linear)"/>
    <path d="M8.00528 25.849H2.28157C1.0155 25.849 0 26.8645 0 28.1306V33.8675C0 35.1203 1.0155 36.149 2.28157 36.149H8.01846C9.27135 36.149 10.3 35.1335 10.3 33.8675V28.1306C10.2868 26.8645 9.27135 25.849 8.00528 25.849ZM8.94164 33.8543C8.94164 34.3686 8.51962 34.7906 8.00528 34.7906H2.28157C1.76723 34.7906 1.3452 34.3686 1.3452 33.8543V28.1174C1.3452 27.603 1.76723 27.181 2.28157 27.181H8.01846C8.53281 27.181 8.95483 27.603 8.95483 28.1174V33.8543H8.94164Z" fill="url(#paint5_linear)"/>
    <path d="M4.90604 33.3004C4.77415 33.4322 4.58952 33.5114 4.39169 33.5114C4.19387 33.5114 4.00923 33.4322 3.87735 33.3004L2.28157 31.7046C1.99143 31.4144 1.99143 30.966 2.28157 30.6759C2.57171 30.3858 3.02011 30.3858 3.31026 30.6759L4.25981 31.6255C4.32575 31.6914 4.44445 31.6914 4.52358 31.6255L7.09529 29.0538C7.38543 28.7636 7.83383 28.7636 8.12397 29.0538C8.41411 29.3439 8.41411 29.7923 8.12397 30.0824L4.90604 33.3004Z" fill="url(#paint6_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="13.5972" y1="11.7112" x2="40" y2="11.7112" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="13.5972" y1="31.2892" x2="40" y2="31.2892" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="3.83215e-08" y1="5.14342" x2="10.2868" y2="5.14342" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="2.06396" y1="5.32477" x2="8.34158" y2="5.32477" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint4_linear" x1="3.83706e-08" y1="18.0745" x2="10.3" y2="18.0745" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint5_linear" x1="3.83706e-08" y1="30.999" x2="10.3" y2="30.999" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint6_linear" x1="2.06396" y1="31.1738" x2="8.34158" y2="31.1738" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='RANKING-STARS'" width="87" height="17" viewBox="0 0 87 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M3.58305 16.2451C3.39545 16.2451 3.23465 16.1894 3.07385 16.0778C2.80585 15.8826 2.67185 15.52 2.75225 15.1575L3.77065 10.4444L0.286654 7.23727C0.0186546 7.01417 -0.0617454 6.62373 0.0454545 6.28908C0.152654 5.95442 0.447454 5.70342 0.769054 5.67554L5.40545 5.22933L7.22785 0.767229C7.36185 0.488348 7.65665 0.265244 8.00505 0.265244C8.35345 0.265244 8.64825 0.488348 8.78225 0.795118L10.6046 5.25722L15.2142 5.70342C15.5626 5.73131 15.8306 5.98231 15.9378 6.31696C16.045 6.65162 15.9378 7.01417 15.6966 7.26516L12.2126 10.4444L13.231 15.1575C13.3114 15.4922 13.1774 15.8547 12.9094 16.0778C12.6414 16.273 12.2662 16.3009 11.9714 16.1336L7.97825 13.6515L4.01185 16.1057C3.87785 16.1894 3.71705 16.2451 3.58305 16.2451Z" fill="url(#paint0_linear)"/>
    <path d="M21.3379 16.2451C21.1503 16.2451 20.9895 16.1894 20.8287 16.0778C20.5607 15.8826 20.4267 15.52 20.5071 15.1575L21.5255 10.4444L18.0415 7.23727C17.7735 7.01417 17.6931 6.62373 17.8003 6.28908C17.9075 5.95442 18.2023 5.70342 18.5239 5.67554L23.1603 5.22933L24.9827 0.767229C25.1167 0.488348 25.4383 0.265244 25.7599 0.265244C26.0815 0.265244 26.4031 0.488348 26.5371 0.795118L28.3595 5.25722L32.9691 5.70342C33.3175 5.73131 33.5855 5.98231 33.6927 6.31696C33.7999 6.65162 33.6927 7.01417 33.4515 7.26516L29.9675 10.4444L30.9859 15.1575C31.0663 15.4922 30.9323 15.8547 30.6643 16.0778C30.3963 16.273 30.0211 16.3009 29.7263 16.1336L25.7331 13.6515L21.7399 16.1336C21.6595 16.1894 21.4987 16.2451 21.3379 16.2451Z" fill="url(#paint1_linear)"/>
    <path d="M39.0928 16.2451C38.9052 16.2451 38.7444 16.1894 38.5836 16.0778C38.3156 15.8826 38.1816 15.52 38.262 15.1575L39.2804 10.4444L35.7964 7.26516C35.5284 7.04205 35.448 6.65162 35.5552 6.31696C35.6624 5.98231 35.9572 5.73131 36.2788 5.70342L40.9152 5.25722L42.7376 0.795118C42.8716 0.46046 43.1664 0.265244 43.5148 0.265244C43.8632 0.265244 44.158 0.488348 44.292 0.795118L46.1144 5.25722L50.724 5.70342C51.0724 5.73131 51.3404 5.98231 51.4476 6.31696C51.5548 6.65162 51.4476 7.01417 51.2064 7.26516L47.7224 10.4444L48.7408 15.1575C48.8212 15.4922 48.6872 15.8547 48.4192 16.0778C48.1512 16.273 47.776 16.3009 47.4812 16.1336L43.5148 13.6236L39.5216 16.1057C39.3876 16.1894 39.2536 16.2451 39.0928 16.2451Z" fill="url(#paint2_linear)"/>
    <path d="M56.8477 16.2451C56.6601 16.2451 56.4993 16.1894 56.3385 16.0778C56.0705 15.8826 55.9365 15.52 56.0169 15.1575L57.0353 10.4444L53.5513 7.26516C53.2833 7.04205 53.2029 6.65162 53.3101 6.31696C53.4173 5.98231 53.7121 5.73131 54.0337 5.70342L58.6701 5.25722L60.4925 0.795118C60.6265 0.46046 60.9213 0.265244 61.2697 0.265244C61.6181 0.265244 61.9129 0.488348 62.0469 0.795118L63.8693 5.25722L68.4789 5.70342C68.8273 5.73131 69.0953 5.98231 69.2025 6.31696C69.3097 6.65162 69.2025 7.01417 68.9613 7.26516L65.4773 10.4444L66.4957 15.1575C66.5761 15.4922 66.4421 15.8547 66.1741 16.0778C65.9061 16.273 65.5309 16.3009 65.2361 16.1336L61.2429 13.6515L57.2497 16.1336C57.1425 16.1894 56.9817 16.2451 56.8477 16.2451ZM61.2697 12.5639C61.4305 12.5639 61.5645 12.6197 61.6985 12.7033L65.4505 15.0459L64.4857 10.5838C64.4053 10.2771 64.5125 9.94242 64.7537 9.71931L68.0501 6.7074L63.6817 6.28908C63.3601 6.26119 63.0921 6.06597 62.9849 5.7592L61.2697 1.5481L59.5545 5.7592C59.4205 6.06597 59.1525 6.26119 58.8577 6.28908L54.4893 6.7074L57.7589 9.71931C58.0001 9.94242 58.1073 10.2771 58.0269 10.5838L57.0621 15.0459L60.8141 12.7033C60.9481 12.5918 61.1089 12.5639 61.2697 12.5639Z" fill="url(#paint3_linear)"/>
    <path d="M74.6036 16.2451C74.416 16.2451 74.2552 16.1894 74.0944 16.0778C73.8264 15.8826 73.6924 15.52 73.7728 15.1575L74.7912 10.4444L71.3072 7.26516C71.0392 7.04205 70.9588 6.65162 71.066 6.31696C71.1732 5.98231 71.468 5.73131 71.7896 5.70342L76.426 5.25722L78.2484 0.795118C78.3824 0.46046 78.6772 0.265244 79.0256 0.265244C79.374 0.265244 79.6688 0.488348 79.8028 0.795118L81.6252 5.25722L86.2348 5.70342C86.5832 5.73131 86.8512 5.98231 86.9584 6.31696C87.0656 6.65162 86.9583 7.01417 86.7171 7.26516L83.2332 10.4444L84.2516 15.1575C84.332 15.4922 84.198 15.8547 83.93 16.0778C83.662 16.273 83.2868 16.3009 82.992 16.1336L78.9988 13.6515L75.0056 16.1336C74.8984 16.1894 74.7644 16.2451 74.6036 16.2451ZM79.0256 12.5639C79.1864 12.5639 79.3204 12.6197 79.4544 12.7033L83.2064 15.0459L82.2416 10.5838C82.1612 10.2771 82.2684 9.94242 82.5096 9.71931L85.8059 6.7074L81.4376 6.28908C81.116 6.26119 80.848 6.06597 80.7408 5.7592L79.0256 1.5481L77.3104 5.7592C77.1764 6.06597 76.9084 6.26119 76.6136 6.28908L72.2452 6.7074L75.5416 9.71931C75.7828 9.94242 75.89 10.2771 75.8096 10.5838L74.8448 15.0459L78.5968 12.7033C78.7308 12.5918 78.8916 12.5639 79.0256 12.5639Z" fill="url(#paint4_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="-0.000488222" y1="8.25519" x2="15.9794" y2="8.25519" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="17.7544" y1="8.25519" x2="33.7343" y2="8.25519" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="35.5093" y1="8.25519" x2="51.4892" y2="8.25519" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="53.2642" y1="8.25519" x2="69.244" y2="8.25519" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint4_linear" x1="71.02" y1="8.25519" x2="86.9999" y2="8.25519" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='RANKING-NUMBERS'" width="77" height="14" viewBox="0 0 77 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.03015 13.7879H2.45059V2.9697L0 3.02273V1.11364L5.03015 0.185608V13.7879Z" fill="url(#paint0_linear)"/>
    <path d="M24.8153 13.7879H16.9993V11.9848L20.6106 7.29167C21.1008 6.60227 21.4361 5.99242 21.6425 5.46212C21.8488 4.93182 21.952 4.48106 21.952 4.05682C21.952 3.47348 21.8488 2.99621 21.6167 2.65152C21.4103 2.30682 21.075 2.12121 20.6622 2.12121C20.2495 2.12121 19.9142 2.33333 19.682 2.75758C19.4498 3.18182 19.3467 3.76515 19.3467 4.45455H16.8187L16.7929 4.40152C16.7671 3.18182 17.1024 2.12121 17.7989 1.27273C18.4954 0.424242 19.4498 0 20.6364 0C21.8488 0 22.8033 0.371212 23.474 1.08712C24.1446 1.80303 24.48 2.78409 24.48 4.0303C24.48 4.85227 24.2994 5.64773 23.9125 6.33712C23.5255 7.05303 22.9064 7.98106 22.0294 9.12121L20.3527 11.6667H24.8153V13.7879Z" fill="url(#paint1_linear)"/>
    <path d="M36.7587 5.83333H38.0484C38.5128 5.83333 38.8481 5.67424 39.0803 5.32955C39.3124 4.98485 39.4156 4.53409 39.4156 3.92424C39.4156 3.39394 39.3124 2.9697 39.0803 2.625C38.8481 2.30682 38.5386 2.12121 38.1258 2.12121C37.7905 2.12121 37.5067 2.2803 37.2488 2.57197C36.9908 2.86364 36.8876 3.26136 36.8876 3.71212H34.3855L34.3597 3.65909C34.3339 2.57197 34.6692 1.69697 35.3915 1.03409C36.1138 0.344697 36.9908 0 38.0484 0C39.235 0 40.2153 0.344697 40.9118 1.00758C41.634 1.67045 41.9952 2.625 41.9952 3.87121C41.9952 4.48106 41.8404 5.06439 41.5308 5.5947C41.2213 6.125 40.8086 6.52273 40.2669 6.81439C40.886 7.07955 41.3503 7.47727 41.6856 8.03409C42.021 8.59091 42.1757 9.25379 42.1757 9.99621C42.1757 11.2424 41.7888 12.197 41.0149 12.9129C40.2411 13.6288 39.2608 13.9735 38.0484 13.9735C36.9908 13.9735 36.088 13.6288 35.3141 12.9659C34.5402 12.303 34.1791 11.3485 34.2049 10.1023L34.2307 10.0492H36.7329C36.7329 10.5795 36.8618 11.0038 37.1198 11.3485C37.3778 11.6932 37.7131 11.8523 38.1 11.8523C38.5386 11.8523 38.8997 11.6667 39.1577 11.322C39.4156 10.9773 39.5446 10.5265 39.5446 9.94318C39.5446 9.22727 39.4156 8.69697 39.1577 8.35227C38.8997 8.00758 38.5128 7.84849 38.0226 7.84849H36.7329V5.83333H36.7587Z" fill="url(#paint2_linear)"/>
    <path d="M58.6592 8.69697H59.7942V10.8182H58.6592V13.7879H56.0797V10.8182H51.6944L51.5654 9.17424L56.0797 0.21212H58.6592V8.69697ZM53.9128 8.69697H56.1055V4.00379H56.0539L55.9249 4.26894L53.9128 8.69697Z" fill="url(#paint3_linear)"/>
    <path d="M69.519 7.76894L70.1639 0.185608H76.6386V2.35985H72.2791L71.9954 5.56818C72.176 5.38258 72.4081 5.22349 72.6919 5.09091C72.9756 4.95833 73.2594 4.9053 73.5689 4.87879C74.6781 4.85227 75.5294 5.25 76.0969 6.04546C76.6902 6.84091 76.9739 7.98106 76.9739 9.41288C76.9739 10.7121 76.6386 11.7992 75.9679 12.6742C75.2972 13.5492 74.3428 13.9735 73.1046 13.9735C72.047 13.9735 71.1441 13.6288 70.3703 12.9394C69.5964 12.25 69.2353 11.2955 69.2611 10.1023L69.2869 10.0492L71.789 9.91667C71.789 10.5265 71.918 11.0038 72.1502 11.3485C72.3823 11.6932 72.7177 11.8523 73.1046 11.8523C73.5689 11.8523 73.8785 11.6402 74.0848 11.1894C74.2912 10.7386 74.3944 10.1553 74.3944 9.43939C74.3944 8.64394 74.2912 8.00758 74.059 7.5303C73.8269 7.05303 73.4657 6.84091 73.0014 6.84091C72.6403 6.84091 72.3565 6.94697 72.176 7.13258C71.9696 7.3447 71.8406 7.58333 71.789 7.92803L69.519 7.76894Z" fill="#E0E0E0"/>
    <defs>
    <linearGradient id="paint0_linear" x1="1.87388e-08" y1="6.98674" x2="5.03015" y2="6.98674" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="16.7915" y1="6.89394" x2="24.8153" y2="6.89394" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="34.2036" y1="6.98674" x2="42.1757" y2="6.98674" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="51.5654" y1="7" x2="59.7942" y2="7" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='SORTING'" width="52" height="33" viewBox="0 0 52 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M51.9999 20.4246H17.6167V12.3885H51.9999V20.4246ZM19.4505 18.6221H50.1662V14.191H19.4505V18.6221ZM51.9999 9.03892H17.6167V0.852627H51.9999V9.03892ZM19.4505 7.23643H50.1662V2.65511H19.4505V7.23643Z" fill="url(#paint0_linear)"/>
    <path d="M51.9999 32.062H17.6167V24.1274H51.9999V32.062ZM19.4505 30.2823H50.1662V25.9071H19.4505V30.2823Z" fill="url(#paint1_linear)"/>
    <path d="M4.66468 2.43955L0.689941 8.5822H3.66727V19.3667H5.6472V8.5822H8.62453L4.66468 2.43955Z" fill="url(#paint2_linear)"/>
    <path d="M11.9958 23.8091V13.019H10.0159V23.8091H7.03857L11.0133 29.9461L14.9732 23.8091H11.9958Z" fill="url(#paint3_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="17.6167" y1="10.6386" x2="51.9999" y2="10.6386" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="17.6167" y1="28.0947" x2="51.9999" y2="28.0947" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="0.689941" y1="10.9031" x2="8.62453" y2="10.9031" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="7.03857" y1="21.4826" x2="14.9732" y2="21.4826" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='DIVIDE_POINTS'" width="47" height="37" viewBox="0 0 47 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M46.9933 7.72834H0.571777V10.5188H46.9933V7.72834Z" fill="url(#paint0_linear)"/>
    <path d="M31.9481 17.2573C36.4294 17.2573 40.0622 13.6244 40.0622 9.14314C40.0622 4.66184 36.4294 1.02902 31.9481 1.02902C27.4668 1.02902 23.834 4.66184 23.834 9.14314C23.834 13.6244 27.4668 17.2573 31.9481 17.2573Z" fill="white"/>
    <path d="M31.948 18.2857C26.9072 18.2857 22.8052 14.1836 22.8052 9.14285C22.8052 4.10207 26.9072 0 31.948 0C36.9888 0 41.0909 4.10207 41.0909 9.14285C41.0909 14.1836 36.9888 18.2857 31.948 18.2857ZM31.948 2.05746C28.0388 2.05746 24.8626 5.23367 24.8626 9.14285C24.8626 13.052 28.0388 16.2282 31.948 16.2282C35.8572 16.2282 39.0334 13.052 39.0334 9.14285C39.0334 5.23367 35.8572 2.05746 31.948 2.05746Z" fill="url(#paint1_linear)"/>
    <path d="M46.9933 25.8317H0.571777V28.6221H46.9933V25.8317Z" fill="url(#paint2_linear)"/>
    <path d="M15.8744 35.3606C20.3557 35.3606 23.9885 31.7278 23.9885 27.2465C23.9885 22.7652 20.3557 19.1323 15.8744 19.1323C11.3931 19.1323 7.76025 22.7652 7.76025 27.2465C7.76025 31.7278 11.3931 35.3606 15.8744 35.3606Z" fill="white"/>
    <path d="M15.8743 36.389C10.8335 36.389 6.73145 32.287 6.73145 27.2462C6.73145 22.2054 10.8335 18.1033 15.8743 18.1033C20.9151 18.1033 25.0171 22.2054 25.0171 27.2462C25.0171 32.287 20.9151 36.389 15.8743 36.389ZM15.8743 20.1608C11.9651 20.1608 8.78891 23.337 8.78891 27.2462C8.78891 31.1554 11.9651 34.3316 15.8743 34.3316C19.7835 34.3316 22.9597 31.1554 22.9597 27.2462C22.9597 23.337 19.7835 20.1608 15.8743 20.1608Z" fill="url(#paint3_linear)"/>
    <path d="M30.1993 12.7563H28.7976V7.0597L27.4731 7.08542V6.0824L30.1993 5.59376V12.7563Z" fill="url(#paint4_linear)"/>
    <path d="M36.4488 9.95298C36.4488 10.9046 36.2559 11.6375 35.883 12.1262C35.51 12.6148 34.9957 12.872 34.3656 12.872C33.7226 12.872 33.2082 12.6277 32.8353 12.1262C32.4496 11.6375 32.2695 10.9046 32.2695 9.95298V8.40988C32.2695 7.4583 32.4624 6.73819 32.8353 6.23668C33.2082 5.73518 33.7226 5.49085 34.3656 5.49085C35.0085 5.49085 35.51 5.73518 35.8958 6.23668C36.2816 6.73819 36.4616 7.4583 36.4616 8.40988V9.95298H36.4488ZM35.0471 8.29415C35.0471 7.71549 34.9828 7.29113 34.8671 7.03395C34.7513 6.76391 34.5713 6.64818 34.3656 6.66104H34.3399C34.1213 6.64818 33.9541 6.76391 33.8383 7.03395C33.7226 7.30399 33.6583 7.71549 33.6583 8.29415V10.0687C33.6583 10.6474 33.7226 11.0846 33.8383 11.3418C33.9541 11.6118 34.1341 11.7275 34.3527 11.7147H34.3784C34.597 11.7404 34.7642 11.6118 34.8799 11.3418C34.9957 11.0717 35.06 10.6474 35.06 10.0687V8.29415H35.0471Z" fill="url(#paint5_linear)"/>
    <path d="M17.0573 27.9277H17.6745V29.0464H17.0573V30.6153H15.6685V29.0336H13.2896L13.2124 28.1592L15.6556 23.4399H17.0573V27.9277ZM14.4855 27.9277H15.6685V25.4587H15.6428L15.5656 25.5873L14.4855 27.9277Z" fill="url(#paint6_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="0.571778" y1="9.12356" x2="46.9933" y2="9.12357" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="22.8052" y1="9.14285" x2="41.0909" y2="9.14285" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="0.571778" y1="27.2269" x2="46.9933" y2="27.2269" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="6.73145" y1="27.2462" x2="25.0171" y2="27.2462" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint4_linear" x1="27.4731" y1="9.17503" x2="30.1993" y2="9.17503" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint5_linear" x1="32.2695" y1="9.18143" x2="36.4616" y2="9.18143" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint6_linear" x1="13.2124" y1="27.0276" x2="17.6745" y2="27.0276" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='IMAGE_CHOICE'" width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5655 0H2.22035C1.00088 0 0 0.982758 0 2.18014V15.2836C0 16.4923 1.00088 17.4637 2.22035 17.4637H15.5655C16.7965 17.4637 17.7858 16.481 17.7858 15.2836V2.18014C17.7858 0.982758 16.7965 0 15.5655 0ZM2.22035 1.45719H15.5655C15.9796 1.45719 16.3018 1.78478 16.3018 2.18014V7.34245L13.8628 4.94768C13.5752 4.66528 13.1035 4.66528 12.8159 4.94768L7.59292 10.0761L5.7177 8.22354C5.43009 7.94114 4.95841 7.94114 4.6708 8.22354L1.48407 11.3526V2.18014C1.48407 1.78478 1.8177 1.45719 2.22035 1.45719ZM15.5655 16.0178H2.22035C1.80619 16.0178 1.48407 15.6902 1.48407 15.2949V13.4084L5.1885 9.77111L9.47965 13.9845C9.6292 14.1314 9.81327 14.1992 10.0088 14.1992C10.2044 14.1992 10.3885 14.1314 10.5381 13.9845C10.8257 13.7021 10.8257 13.239 10.5381 12.9566L8.65133 11.104L13.3451 6.48395L16.3133 9.39833V15.2836C16.3133 15.6902 15.9796 16.0178 15.5655 16.0178Z" fill="#828282"/>
    <path d="M5.92469 4.37158C5.92469 5.1736 5.25743 5.82877 4.44061 5.82877C3.6238 5.82877 2.95654 5.1736 2.95654 4.37158C2.95654 3.56956 3.6238 2.91438 4.44061 2.91438C5.25743 2.91438 5.92469 3.55826 5.92469 4.37158Z" fill="#828282"/>
    <path d="M36.7798 0H23.4347C22.2037 0 21.2144 0.982759 21.2144 2.18014V15.2836C21.2144 16.4923 22.2152 17.4637 23.4347 17.4637H36.7798C38.0108 17.4637 39.0002 16.481 39.0002 15.2836V2.18014C39.0002 0.982759 37.9993 0 36.7798 0ZM23.4347 1.45719H36.7798C37.194 1.45719 37.5161 1.78478 37.5161 2.18014V7.34245L35.0772 4.94768C34.7896 4.66528 34.3179 4.66528 34.0303 4.94768L28.8073 10.0761L26.9205 8.22354C26.6329 7.94114 26.1613 7.94114 25.8736 8.22354L22.6869 11.3526V2.18014C22.6869 1.78478 23.0206 1.45719 23.4347 1.45719ZM36.7798 16.0178H23.4347C23.0206 16.0178 22.6984 15.6903 22.6984 15.2949V13.4084L26.4029 9.77111L30.694 13.9845C30.8436 14.1314 31.0276 14.1992 31.2232 14.1992C31.4188 14.1992 31.6028 14.1314 31.7524 13.9845C32.04 13.7021 32.04 13.239 31.7524 12.9566L29.8657 11.104L34.5595 6.49524L37.5276 9.40963V15.2949C37.5161 15.6903 37.1825 16.0178 36.7798 16.0178Z" fill="url(#paint0_linear)"/>
    <path d="M27.139 4.37158C27.139 5.1736 26.4718 5.82877 25.655 5.82877C24.8382 5.82877 24.1709 5.1736 24.1709 4.37158C24.1709 3.56956 24.8382 2.91438 25.655 2.91438C26.4718 2.91438 27.139 3.55826 27.139 4.37158Z" fill="url(#paint1_linear)"/>
    <path d="M15.5655 20.525H2.22035C1.00088 20.525 0 21.5077 0 22.7164V35.8199C0 37.0285 1.00088 38 2.22035 38H15.5655C16.7965 38 17.7858 37.0172 17.7858 35.8199V22.7164C17.7858 21.5077 16.7965 20.525 15.5655 20.525ZM2.22035 21.9822H15.5655C15.9796 21.9822 16.3018 22.3097 16.3018 22.7051V27.8674L13.8628 25.4726C13.5752 25.1902 13.1035 25.1902 12.8159 25.4726L7.59292 30.6011L5.70619 28.7485C5.41858 28.4661 4.9469 28.4661 4.65929 28.7485L1.47257 31.8775V22.7164C1.48407 22.3097 1.8177 21.9822 2.22035 21.9822ZM15.5655 36.5428H2.22035C1.80619 36.5428 1.48407 36.2152 1.48407 35.8199V33.9334L5.1885 30.2961L9.47965 34.5095C9.6292 34.6564 9.81327 34.7241 10.0088 34.7241C10.2044 34.7241 10.3885 34.6564 10.5381 34.5095C10.8257 34.2271 10.8257 33.764 10.5381 33.4816L8.65133 31.629L13.3451 27.0202L16.3133 29.9346V35.8086C16.3133 36.2152 15.9796 36.5428 15.5655 36.5428Z" fill="#828282"/>
    <path d="M5.92469 24.8966C5.92469 25.6986 5.25743 26.3537 4.44061 26.3537C3.6238 26.3537 2.95654 25.6986 2.95654 24.8966C2.95654 24.0945 3.6238 23.4394 4.44061 23.4394C5.25743 23.4394 5.92469 24.0945 5.92469 24.8966Z" fill="#828282"/>
    <path d="M36.7798 20.525H23.4347C22.2037 20.525 21.2144 21.5077 21.2144 22.7051V35.8086C21.2144 37.0172 22.2152 37.9887 23.4347 37.9887H36.7798C38.0108 37.9887 39.0002 37.0059 39.0002 35.8086V22.7051C39.0002 21.5077 37.9993 20.525 36.7798 20.525ZM23.4347 21.9822H36.7798C37.194 21.9822 37.5161 22.3097 37.5161 22.7051V27.8674L35.0772 25.4726C34.7896 25.1902 34.3179 25.1902 34.0303 25.4726L28.8073 30.6011L26.9206 28.7485C26.6329 28.4661 26.1613 28.4661 25.8736 28.7485L22.6869 31.8775V22.7164C22.6869 22.3097 23.0206 21.9822 23.4347 21.9822ZM36.7798 36.5428H23.4347C23.0206 36.5428 22.6984 36.2152 22.6984 35.8199V33.9334L26.4029 30.2961L30.694 34.5095C30.8436 34.6564 31.0276 34.7241 31.2232 34.7241C31.4188 34.7241 31.6029 34.6564 31.7524 34.5095C32.04 34.2271 32.04 33.764 31.7524 33.4816L29.8542 31.629L34.548 27.0202L37.5161 29.9346V35.8086C37.5161 36.2152 37.1825 36.5428 36.7798 36.5428Z" fill="#828282"/>
    <path d="M27.139 24.8966C27.139 25.6986 26.4718 26.3537 25.655 26.3537C24.8382 26.3537 24.1709 25.6986 24.1709 24.8966C24.1709 24.0945 24.8382 23.4394 25.655 23.4394C26.4718 23.4394 27.139 24.0945 27.139 24.8966Z" fill="#828282"/>
    <defs>
    <linearGradient id="paint0_linear" x1="21.2144" y1="8.73187" x2="39.0002" y2="8.73187" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="24.1709" y1="4.37158" x2="27.139" y2="4.37158" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='MULTIPLEIMAGE_CHOICE'" width="39" height="38" viewBox="0 0 39 38" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5655 0H2.22035C1.00088 0 0 0.982758 0 2.18014V15.2836C0 16.4923 1.00088 17.4637 2.22035 17.4637H15.5655C16.7965 17.4637 17.7858 16.481 17.7858 15.2836V2.18014C17.7858 0.982758 16.7965 0 15.5655 0ZM2.22035 1.45719H15.5655C15.9796 1.45719 16.3018 1.78478 16.3018 2.18014V7.34245L13.8628 4.94768C13.5752 4.66528 13.1035 4.66528 12.8159 4.94768L7.59292 10.0761L5.7177 8.22354C5.43009 7.94114 4.95841 7.94114 4.6708 8.22354L1.48407 11.3526V2.18014C1.48407 1.78478 1.8177 1.45719 2.22035 1.45719ZM15.5655 16.0178H2.22035C1.80619 16.0178 1.48407 15.6902 1.48407 15.2949V13.4084L5.1885 9.77111L9.47965 13.9845C9.6292 14.1314 9.81327 14.1992 10.0088 14.1992C10.2044 14.1992 10.3885 14.1314 10.5381 13.9845C10.8257 13.7021 10.8257 13.239 10.5381 12.9566L8.65133 11.104L13.3451 6.48395L16.3133 9.39833V15.2836C16.3133 15.6902 15.9796 16.0178 15.5655 16.0178Z" fill="#828282"/>
    <path d="M5.92469 4.37158C5.92469 5.1736 5.25743 5.82877 4.44061 5.82877C3.6238 5.82877 2.95654 5.1736 2.95654 4.37158C2.95654 3.56956 3.6238 2.91438 4.44061 2.91438C5.25743 2.91438 5.92469 3.55826 5.92469 4.37158Z" fill="#828282"/>
    <path d="M36.7798 0H23.4347C22.2037 0 21.2144 0.982759 21.2144 2.18014V15.2836C21.2144 16.4923 22.2152 17.4637 23.4347 17.4637H36.7798C38.0108 17.4637 39.0002 16.481 39.0002 15.2836V2.18014C39.0002 0.982759 37.9993 0 36.7798 0ZM23.4347 1.45719H36.7798C37.194 1.45719 37.5161 1.78478 37.5161 2.18014V7.34245L35.0772 4.94768C34.7896 4.66528 34.3179 4.66528 34.0303 4.94768L28.8073 10.0761L26.9205 8.22354C26.6329 7.94114 26.1613 7.94114 25.8736 8.22354L22.6869 11.3526V2.18014C22.6869 1.78478 23.0206 1.45719 23.4347 1.45719ZM36.7798 16.0178H23.4347C23.0206 16.0178 22.6984 15.6903 22.6984 15.2949V13.4084L26.4029 9.77111L30.694 13.9845C30.8436 14.1314 31.0276 14.1992 31.2232 14.1992C31.4188 14.1992 31.6028 14.1314 31.7524 13.9845C32.04 13.7021 32.04 13.239 31.7524 12.9566L29.8657 11.104L34.5595 6.49524L37.5276 9.40963V15.2949C37.5161 15.6903 37.1825 16.0178 36.7798 16.0178Z" fill="url(#paint0_linear)"/>
    <path d="M27.139 4.37158C27.139 5.1736 26.4718 5.82877 25.655 5.82877C24.8382 5.82877 24.1709 5.1736 24.1709 4.37158C24.1709 3.56956 24.8382 2.91438 25.655 2.91438C26.4718 2.91438 27.139 3.55826 27.139 4.37158Z" fill="url(#paint1_linear)"/>
    <path d="M15.5655 20.525H2.22035C1.00088 20.525 0 21.5077 0 22.7164V35.8199C0 37.0285 1.00088 38 2.22035 38H15.5655C16.7965 38 17.7858 37.0172 17.7858 35.8199V22.7164C17.7858 21.5077 16.7965 20.525 15.5655 20.525ZM2.22035 21.9822H15.5655C15.9796 21.9822 16.3018 22.3097 16.3018 22.7051V27.8674L13.8628 25.4726C13.5752 25.1902 13.1035 25.1902 12.8159 25.4726L7.59292 30.6011L5.70619 28.7485C5.41858 28.4661 4.9469 28.4661 4.65929 28.7485L1.47257 31.8775V22.7164C1.48407 22.3097 1.8177 21.9822 2.22035 21.9822ZM15.5655 36.5428H2.22035C1.80619 36.5428 1.48407 36.2152 1.48407 35.8199V33.9334L5.1885 30.2961L9.47965 34.5095C9.6292 34.6564 9.81327 34.7241 10.0088 34.7241C10.2044 34.7241 10.3885 34.6564 10.5381 34.5095C10.8257 34.2271 10.8257 33.764 10.5381 33.4816L8.65133 31.629L13.3451 27.0202L16.3133 29.9346V35.8086C16.3133 36.2152 15.9796 36.5428 15.5655 36.5428Z" fill="url(#paint2_linear)"/>
    <path d="M5.92469 24.8966C5.92469 25.6986 5.25743 26.3537 4.44061 26.3537C3.6238 26.3537 2.95654 25.6986 2.95654 24.8966C2.95654 24.0945 3.6238 23.4394 4.44061 23.4394C5.25743 23.4394 5.92469 24.0945 5.92469 24.8966Z" fill="url(#paint3_linear)"/>
    <path d="M36.7798 20.525H23.4347C22.2037 20.525 21.2144 21.5077 21.2144 22.7051V35.8086C21.2144 37.0172 22.2152 37.9887 23.4347 37.9887H36.7798C38.0108 37.9887 39.0002 37.0059 39.0002 35.8086V22.7051C39.0002 21.5077 37.9993 20.525 36.7798 20.525ZM23.4347 21.9822H36.7798C37.194 21.9822 37.5161 22.3097 37.5161 22.7051V27.8674L35.0772 25.4726C34.7896 25.1902 34.3179 25.1902 34.0303 25.4726L28.8073 30.6011L26.9206 28.7485C26.6329 28.4661 26.1613 28.4661 25.8736 28.7485L22.6869 31.8775V22.7164C22.6869 22.3097 23.0206 21.9822 23.4347 21.9822ZM36.7798 36.5428H23.4347C23.0206 36.5428 22.6984 36.2152 22.6984 35.8199V33.9334L26.4029 30.2961L30.694 34.5095C30.8436 34.6564 31.0276 34.7241 31.2232 34.7241C31.4188 34.7241 31.6029 34.6564 31.7524 34.5095C32.04 34.2271 32.04 33.764 31.7524 33.4816L29.8542 31.629L34.548 27.0202L37.5161 29.9346V35.8086C37.5161 36.2152 37.1825 36.5428 36.7798 36.5428Z" fill="#828282"/>
    <path d="M27.139 24.8966C27.139 25.6986 26.4718 26.3537 25.655 26.3537C24.8382 26.3537 24.1709 25.6986 24.1709 24.8966C24.1709 24.0945 24.8382 23.4394 25.655 23.4394C26.4718 23.4394 27.139 24.0945 27.139 24.8966Z" fill="#828282"/>
    <defs>
    <linearGradient id="paint0_linear" x1="21.2144" y1="8.73187" x2="39.0002" y2="8.73187" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="24.1709" y1="4.37158" x2="27.139" y2="4.37158" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="6.62574e-08" y1="29.2625" x2="17.7858" y2="29.2625" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="2.95654" y1="24.8966" x2="5.92469" y2="24.8966" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='IMAGE_URL'" width="46" height="36" viewBox="0 0 46 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M28.2053 2.89119H4.025C1.80066 2.89119 0 4.74093 0 7.0259V31.8653C0 34.1503 1.80066 36 4.025 36H28.2053C30.4296 36 32.2303 34.1503 32.2303 31.8653V7.0259C32.2303 4.74093 30.4296 2.89119 28.2053 2.89119ZM4.025 5.64248H28.2053C28.9467 5.64248 29.552 6.26425 29.552 7.0259V16.8031L25.1184 12.2642C24.5888 11.7202 23.7414 11.7202 23.227 12.2642L13.7697 21.9793L10.35 18.4663C9.8204 17.9223 8.97303 17.9223 8.45855 18.4663L2.69342 24.3886V7.0259C2.69342 6.26425 3.28355 5.64248 4.025 5.64248ZM28.2053 33.2332H4.025C3.28355 33.2332 2.67829 32.6114 2.67829 31.8497V28.2746L9.39671 21.3731L17.1743 29.3627C17.4316 29.6269 17.7796 29.7668 18.1276 29.7668C18.4757 29.7668 18.8086 29.6269 19.0809 29.3627C19.6105 28.8186 19.6105 27.9482 19.0809 27.4197L15.6612 23.9067L24.1651 15.171L29.5368 20.6891V31.8653C29.5368 32.6269 28.9467 33.2332 28.2053 33.2332Z" fill="#747474"/>
    <path d="M10.7433 11.1606C10.7433 12.6839 9.54789 13.9275 8.04987 13.9275C6.55184 13.9275 5.35645 12.6995 5.35645 11.1606C5.35645 9.62176 6.55184 8.39378 8.04987 8.39378C9.54789 8.39378 10.7433 9.63731 10.7433 11.1606Z" fill="#747474"/>
    <path d="M38.7062 6.00888C38.1009 2.91562 35.1654 0.89489 32.1542 1.51665C29.945 1.96743 28.22 3.73945 27.7812 6.00888C25.6476 6.25758 24.1042 8.24722 24.3463 10.4545C24.5733 12.4752 26.2377 13.9985 28.22 13.9985H31.0042C31.3068 13.9985 31.5641 13.7498 31.5641 13.4234C31.5641 13.1125 31.322 12.8483 31.0042 12.8483H28.22C26.6766 12.8327 25.4358 11.5426 25.4509 9.97261C25.466 8.38712 26.722 7.11251 28.2502 7.12805C28.5377 7.12805 28.7798 6.91043 28.8101 6.6151C29.0976 4.09696 31.3068 2.3094 33.743 2.58919C35.8009 2.8379 37.42 4.50111 37.6621 6.6151C37.7075 6.91043 37.9647 7.12805 38.2522 7.12805C39.7956 7.12805 41.0364 8.40266 41.0364 9.98815C41.0364 11.5736 39.7956 12.8483 38.2522 12.8483H35.468C35.1654 12.8483 34.9081 13.097 34.9081 13.4234C34.9081 13.7343 35.1502 13.9985 35.468 13.9985H38.2522C40.4009 13.983 42.141 12.1799 42.1259 9.97261C42.1108 7.95188 40.643 6.25758 38.7062 6.00888Z" fill="white"/>
    <path d="M28.2203 17.9622C24.2709 17.9622 20.9571 14.9312 20.5032 10.8897C20.1249 7.45447 21.9255 4.28349 24.7703 2.82235C26.0413 0.164321 28.4624 -1.79423 31.3979 -2.40045C35.6801 -3.27091 39.8867 -1.01703 41.7176 2.8379C44.2597 4.15914 45.9847 6.8638 45.9999 9.92598C46.0301 14.3249 42.565 17.9312 38.2827 17.9622H35.4683C34.6512 17.9622 33.8946 17.7291 33.2439 17.3405C32.5933 17.7291 31.8216 17.9622 31.0196 17.9622H28.2203ZM32.0031 8.99333C32.442 9.10214 32.8656 9.27313 33.2439 9.49074C33.6222 9.25758 34.0459 9.0866 34.4847 8.97779C34.167 8.46484 33.9551 7.87416 33.8492 7.2524L33.8189 7.06587C33.7887 6.78608 33.5768 6.56846 33.3045 6.53738C33.1078 6.50629 32.9564 6.59955 32.8808 6.66173C32.79 6.73945 32.6841 6.8638 32.6689 7.06587C32.5781 7.7809 32.3512 8.41821 32.0031 8.99333Z" fill="white"/>
    <path d="M35.1509 14.4804C35.4082 14.7446 35.8167 14.7602 36.074 14.4959C36.3312 14.2317 36.3463 13.812 36.0891 13.5477L36.074 13.5322L33.3049 10.6876C33.0476 10.4234 32.6391 10.4234 32.3819 10.6876L29.6128 13.5322C29.3555 13.7964 29.3404 14.2161 29.5976 14.4804C29.8549 14.7446 30.2634 14.7602 30.5207 14.4959L30.5358 14.4804L32.1851 12.7861V21.2265C32.1851 21.5995 32.4726 21.8949 32.8358 21.8949C33.199 21.8949 33.4865 21.5995 33.4865 21.2265V12.7861L35.1509 14.4804Z" fill="white"/>
    <path d="M32.8358 23.527C31.595 23.527 30.5963 22.5011 30.5963 21.2265V16.2524C29.8397 16.4389 29.0226 16.2213 28.4476 15.6151C27.6003 14.7135 27.6154 13.2524 28.493 12.3664L31.1108 9.67726L31.141 9.63063L31.3075 9.47519C31.4891 9.30421 31.6858 9.16431 31.9127 9.0555V8.82234L32.0641 8.97778C32.3062 8.88452 32.5785 8.83789 32.8509 8.83789C33.1233 8.83789 33.3805 8.88452 33.6226 8.97778L33.7739 8.82234V9.0555C34.016 9.16431 34.2279 9.31975 34.4246 9.52182L37.1937 12.3664C37.2088 12.3819 37.2239 12.3975 37.2391 12.413C38.0864 13.3301 38.0562 14.7757 37.1785 15.6617C36.7549 16.0814 36.1799 16.3146 35.5746 16.3146C35.4081 16.3146 35.2417 16.2835 35.0752 16.2524V21.2265C35.0752 22.5011 34.0766 23.527 32.8358 23.527Z" fill="white"/>
    <path d="M39.9473 5.71354C39.2361 2.07624 35.8012 -0.286458 32.2604 0.444111C29.6729 0.972609 27.6453 3.05551 27.1308 5.71354C24.619 6.00888 22.8183 8.34048 23.1058 10.9208C23.3782 13.299 25.3302 15.0866 27.6453 15.0866H30.9137C31.2769 15.0866 31.5644 14.7913 31.5644 14.4182C31.5644 14.0451 31.2769 13.7498 30.9137 13.7498H27.6453C25.8447 13.7343 24.392 12.2265 24.392 10.3768C24.4072 8.52701 25.8749 7.03478 27.6756 7.03478C28.0085 7.03478 28.2808 6.77053 28.3262 6.44411C28.6591 3.49074 31.2466 1.3923 34.1216 1.73427C36.5275 2.01406 38.4341 3.97261 38.7065 6.44411C38.767 6.78608 39.0545 7.05033 39.3874 7.03478C41.1881 7.03478 42.6558 8.54256 42.6558 10.3923C42.6558 12.242 41.1881 13.7498 39.3874 13.7498H36.1644C35.8012 13.7498 35.5137 14.0451 35.5137 14.4182C35.5137 14.7913 35.8012 15.0866 36.1644 15.0866H39.4328C41.9598 15.0711 43.9874 12.9571 43.9723 10.3612C43.9571 7.98297 42.2321 6.00888 39.9473 5.71354Z" fill="url(#paint0_linear)"/>
    <path d="M35.8462 12.3664C36.1035 12.6307 36.512 12.6462 36.7693 12.3819C37.0265 12.1177 37.0416 11.698 36.7844 11.4338L36.7693 11.4182L34.0002 8.57366C33.7429 8.30941 33.3344 8.30941 33.0772 8.57366L30.3081 11.4182C30.0508 11.6825 30.0357 12.1022 30.293 12.3664C30.5502 12.6307 30.9587 12.6462 31.216 12.3819L31.2311 12.3664L32.8805 10.6721V19.1125C32.8805 19.4856 33.168 19.7809 33.5311 19.7809C33.8943 19.7809 34.1818 19.4856 34.1818 19.1125V10.6566L35.8462 12.3664Z" fill="url(#paint1_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="23.0757" y1="7.69907" x2="43.9724" y2="7.69907" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="30.1074" y1="14.0782" x2="36.9699" y2="14.0782" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='DATE'" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M27.1406 16.1719C27.9173 16.1719 28.5469 15.5423 28.5469 14.7656C28.5469 13.989 27.9173 13.3594 27.1406 13.3594C26.364 13.3594 25.7344 13.989 25.7344 14.7656C25.7344 15.5423 26.364 16.1719 27.1406 16.1719Z" fill="url(#paint0_linear)"/>
    <path d="M30.375 2.8125H28.5469V1.40625C28.5469 0.629578 27.9173 0 27.1406 0C26.364 0 25.7344 0.629578 25.7344 1.40625V2.8125H19.3359V1.40625C19.3359 0.629578 18.7064 0 17.9297 0C17.153 0 16.5234 0.629578 16.5234 1.40625V2.8125H10.1953V1.40625C10.1953 0.629578 9.56573 0 8.78906 0C8.01239 0 7.38281 0.629578 7.38281 1.40625V2.8125H5.625C2.52338 2.8125 0 5.33588 0 8.4375V30.375C0 33.4766 2.52338 36 5.625 36H16.3828C17.1595 36 17.7891 35.3704 17.7891 34.5938C17.7891 33.8171 17.1595 33.1875 16.3828 33.1875H5.625C4.07419 33.1875 2.8125 31.9258 2.8125 30.375V8.4375C2.8125 6.88669 4.07419 5.625 5.625 5.625H7.38281V7.03125C7.38281 7.80792 8.01239 8.4375 8.78906 8.4375C9.56573 8.4375 10.1953 7.80792 10.1953 7.03125V5.625H16.5234V7.03125C16.5234 7.80792 17.153 8.4375 17.9297 8.4375C18.7064 8.4375 19.3359 7.80792 19.3359 7.03125V5.625H25.7344V7.03125C25.7344 7.80792 26.364 8.4375 27.1406 8.4375C27.9173 8.4375 28.5469 7.80792 28.5469 7.03125V5.625H30.375C31.9258 5.625 33.1875 6.88669 33.1875 8.4375V16.4531C33.1875 17.2298 33.8171 17.8594 34.5938 17.8594C35.3704 17.8594 36 17.2298 36 16.4531V8.4375C36 5.33588 33.4766 2.8125 30.375 2.8125Z" fill="url(#paint1_linear)"/>
    <path d="M27.4922 18.9844C22.8009 18.9844 18.9844 22.8009 18.9844 27.4922C18.9844 32.1834 22.8009 36 27.4922 36C32.1834 36 36 32.1834 36 27.4922C36 22.8009 32.1834 18.9844 27.4922 18.9844ZM27.4922 33.1875C24.3518 33.1875 21.7969 30.6326 21.7969 27.4922C21.7969 24.3517 24.3518 21.7969 27.4922 21.7969C30.6326 21.7969 33.1875 24.3517 33.1875 27.4922C33.1875 30.6326 30.6326 33.1875 27.4922 33.1875Z" fill="url(#paint2_linear)"/>
    <path d="M29.5312 26.0859H28.8984V24.6094C28.8984 23.8327 28.2689 23.2031 27.4922 23.2031C26.7155 23.2031 26.0859 23.8327 26.0859 24.6094V27.4922C26.0859 28.2689 26.7155 28.8984 27.4922 28.8984H29.5312C30.3079 28.8984 30.9375 28.2689 30.9375 27.4922C30.9375 26.7155 30.3079 26.0859 29.5312 26.0859Z" fill="url(#paint3_linear)"/>
    <path d="M21.0234 16.1719C21.8001 16.1719 22.4297 15.5423 22.4297 14.7656C22.4297 13.989 21.8001 13.3594 21.0234 13.3594C20.2468 13.3594 19.6172 13.989 19.6172 14.7656C19.6172 15.5423 20.2468 16.1719 21.0234 16.1719Z" fill="url(#paint4_linear)"/>
    <path d="M14.9062 22.2891C15.6829 22.2891 16.3125 21.6595 16.3125 20.8828C16.3125 20.1062 15.6829 19.4766 14.9062 19.4766C14.1296 19.4766 13.5 20.1062 13.5 20.8828C13.5 21.6595 14.1296 22.2891 14.9062 22.2891Z" fill="url(#paint5_linear)"/>
    <path d="M8.78906 16.1719C9.56571 16.1719 10.1953 15.5423 10.1953 14.7656C10.1953 13.989 9.56571 13.3594 8.78906 13.3594C8.01241 13.3594 7.38281 13.989 7.38281 14.7656C7.38281 15.5423 8.01241 16.1719 8.78906 16.1719Z" fill="url(#paint6_linear)"/>
    <path d="M8.78906 22.2891C9.56571 22.2891 10.1953 21.6595 10.1953 20.8828C10.1953 20.1062 9.56571 19.4766 8.78906 19.4766C8.01241 19.4766 7.38281 20.1062 7.38281 20.8828C7.38281 21.6595 8.01241 22.2891 8.78906 22.2891Z" fill="url(#paint7_linear)"/>
    <path d="M8.78906 28.4062C9.56571 28.4062 10.1953 27.7767 10.1953 27C10.1953 26.2233 9.56571 25.5938 8.78906 25.5938C8.01241 25.5938 7.38281 26.2233 7.38281 27C7.38281 27.7767 8.01241 28.4062 8.78906 28.4062Z" fill="url(#paint8_linear)"/>
    <path d="M14.9062 28.4062C15.6829 28.4062 16.3125 27.7767 16.3125 27C16.3125 26.2233 15.6829 25.5938 14.9062 25.5938C14.1296 25.5938 13.5 26.2233 13.5 27C13.5 27.7767 14.1296 28.4062 14.9062 28.4062Z" fill="url(#paint9_linear)"/>
    <path d="M14.9062 16.1719C15.6829 16.1719 16.3125 15.5423 16.3125 14.7656C16.3125 13.989 15.6829 13.3594 14.9062 13.3594C14.1296 13.3594 13.5 13.989 13.5 14.7656C13.5 15.5423 14.1296 16.1719 14.9062 16.1719Z" fill="url(#paint10_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="25.7344" y1="14.7656" x2="28.5469" y2="14.7656" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="1.3411e-07" y1="18" x2="36" y2="18" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="18.9844" y1="27.4922" x2="36" y2="27.4922" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="26.0859" y1="26.0508" x2="30.9375" y2="26.0508" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint4_linear" x1="19.6172" y1="14.7656" x2="22.4297" y2="14.7656" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint5_linear" x1="13.5" y1="20.8828" x2="16.3125" y2="20.8828" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint6_linear" x1="7.38281" y1="14.7656" x2="10.1953" y2="14.7656" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint7_linear" x1="7.38281" y1="20.8828" x2="10.1953" y2="20.8828" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint8_linear" x1="7.38281" y1="27" x2="10.1953" y2="27" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint9_linear" x1="13.5" y1="27" x2="16.3125" y2="27" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint10_linear" x1="13.5" y1="14.7656" x2="16.3125" y2="14.7656" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='MATRIX'" width="77" height="36" viewBox="0 0 77 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.03015 13.7879H2.45059V2.9697L0 3.02273V1.11364L5.03015 0.185608V13.7879Z" fill="#BDBDBD"/>
    <path d="M24.8153 13.7879H16.9993V11.9848L20.6106 7.29167C21.1008 6.60227 21.4361 5.99242 21.6425 5.46212C21.8488 4.93182 21.952 4.48106 21.952 4.05682C21.952 3.47348 21.8488 2.99621 21.6167 2.65152C21.4103 2.30682 21.075 2.12121 20.6622 2.12121C20.2495 2.12121 19.9142 2.33333 19.682 2.75758C19.4498 3.18182 19.3467 3.76515 19.3467 4.45455H16.8187L16.7929 4.40152C16.7671 3.18182 17.1024 2.12121 17.7989 1.27273C18.4954 0.424242 19.4498 0 20.6364 0C21.8488 0 22.8033 0.371212 23.474 1.08712C24.1446 1.80303 24.48 2.78409 24.48 4.0303C24.48 4.85227 24.2994 5.64773 23.9125 6.33712C23.5255 7.05303 22.9064 7.98106 22.0294 9.12121L20.3527 11.6667H24.8153V13.7879Z" fill="#BDBDBD"/>
    <path d="M36.7587 5.83333H38.0484C38.5128 5.83333 38.8481 5.67424 39.0803 5.32955C39.3124 4.98485 39.4156 4.53409 39.4156 3.92424C39.4156 3.39394 39.3124 2.9697 39.0803 2.625C38.8481 2.30682 38.5386 2.12121 38.1258 2.12121C37.7905 2.12121 37.5067 2.2803 37.2488 2.57197C36.9908 2.86364 36.8876 3.26136 36.8876 3.71212H34.3855L34.3597 3.65909C34.3339 2.57197 34.6692 1.69697 35.3915 1.03409C36.1138 0.344697 36.9908 0 38.0484 0C39.235 0 40.2153 0.344697 40.9118 1.00758C41.634 1.67045 41.9952 2.625 41.9952 3.87121C41.9952 4.48106 41.8404 5.06439 41.5308 5.5947C41.2213 6.125 40.8086 6.52273 40.2669 6.81439C40.886 7.07955 41.3503 7.47727 41.6856 8.03409C42.021 8.59091 42.1757 9.25379 42.1757 9.99621C42.1757 11.2424 41.7888 12.197 41.0149 12.9129C40.2411 13.6288 39.2608 13.9735 38.0484 13.9735C36.9908 13.9735 36.088 13.6288 35.3141 12.9659C34.5402 12.303 34.1791 11.3485 34.2049 10.1023L34.2307 10.0492H36.7329C36.7329 10.5795 36.8618 11.0038 37.1198 11.3485C37.3778 11.6932 37.7131 11.8523 38.1 11.8523C38.5386 11.8523 38.8997 11.6667 39.1577 11.322C39.4156 10.9773 39.5446 10.5265 39.5446 9.94318C39.5446 9.22727 39.4156 8.69697 39.1577 8.35227C38.8997 8.00758 38.5128 7.84849 38.0226 7.84849H36.7329V5.83333H36.7587Z" fill="#BDBDBD"/>
    <path d="M58.6592 8.69698H59.7942V10.8182H58.6592V13.7879H56.0797V10.8182H51.6944L51.5654 9.17425L56.0797 0.212128H58.6592V8.69698ZM53.9128 8.69698H56.1055V4.0038H56.0539L55.9249 4.26895L53.9128 8.69698Z" fill="url(#paint0_linear)"/>
    <path d="M69.519 7.76894L70.1639 0.185608H76.6386V2.35985H72.2791L71.9954 5.56818C72.176 5.38258 72.4081 5.22349 72.6919 5.09091C72.9756 4.95833 73.2594 4.9053 73.5689 4.87879C74.6781 4.85227 75.5294 5.25 76.0969 6.04546C76.6902 6.84091 76.9739 7.98106 76.9739 9.41288C76.9739 10.7121 76.6386 11.7992 75.9679 12.6742C75.2972 13.5492 74.3428 13.9735 73.1046 13.9735C72.047 13.9735 71.1441 13.6288 70.3703 12.9394C69.5964 12.25 69.2353 11.2955 69.2611 10.1023L69.2869 10.0492L71.789 9.91667C71.789 10.5265 71.918 11.0038 72.1502 11.3485C72.3823 11.6932 72.7177 11.8523 73.1046 11.8523C73.5689 11.8523 73.8785 11.6402 74.0848 11.1894C74.2912 10.7386 74.3944 10.1553 74.3944 9.43939C74.3944 8.64394 74.2912 8.00758 74.059 7.5303C73.8269 7.05303 73.4657 6.84091 73.0014 6.84091C72.6403 6.84091 72.3565 6.94697 72.176 7.13258C71.9696 7.3447 71.8406 7.58333 71.789 7.92803L69.519 7.76894Z" fill="#BDBDBD"/>
    <path d="M5.03015 35.7879H2.45059V24.9697L0 25.0227V23.1136L5.03015 22.1856V35.7879Z" fill="#BDBDBD"/>
    <path d="M24.8153 35.7879H16.9993V33.9848L20.6106 29.2917C21.1008 28.6023 21.4361 27.9924 21.6425 27.4621C21.8488 26.9318 21.952 26.4811 21.952 26.0568C21.952 25.4735 21.8488 24.9962 21.6167 24.6515C21.4103 24.3068 21.075 24.1212 20.6622 24.1212C20.2495 24.1212 19.9142 24.3333 19.682 24.7576C19.4498 25.1818 19.3467 25.7652 19.3467 26.4545H16.8187L16.7929 26.4015C16.7671 25.1818 17.1024 24.1212 17.7989 23.2727C18.4954 22.4242 19.4498 22 20.6364 22C21.8488 22 22.8033 22.3712 23.474 23.0871C24.1446 23.803 24.48 24.7841 24.48 26.0303C24.48 26.8523 24.2994 27.6477 23.9125 28.3371C23.5255 29.053 22.9064 29.9811 22.0294 31.1212L20.3527 33.6667H24.8153V35.7879Z" fill="url(#paint1_linear)"/>
    <path d="M36.7587 27.8333H38.0484C38.5128 27.8333 38.8481 27.6742 39.0803 27.3295C39.3124 26.9848 39.4156 26.5341 39.4156 25.9242C39.4156 25.3939 39.3124 24.9697 39.0803 24.625C38.8481 24.3068 38.5386 24.1212 38.1258 24.1212C37.7905 24.1212 37.5067 24.2803 37.2488 24.572C36.9908 24.8636 36.8876 25.2614 36.8876 25.7121H34.3855L34.3597 25.6591C34.3339 24.572 34.6692 23.697 35.3915 23.0341C36.1138 22.3447 36.9908 22 38.0484 22C39.235 22 40.2153 22.3447 40.9118 23.0076C41.634 23.6705 41.9952 24.625 41.9952 25.8712C41.9952 26.4811 41.8404 27.0644 41.5308 27.5947C41.2213 28.125 40.8086 28.5227 40.2669 28.8144C40.886 29.0795 41.3503 29.4773 41.6856 30.0341C42.021 30.5909 42.1757 31.2538 42.1757 31.9962C42.1757 33.2424 41.7888 34.197 41.0149 34.9129C40.2411 35.6288 39.2608 35.9735 38.0484 35.9735C36.9908 35.9735 36.088 35.6288 35.3141 34.9659C34.5402 34.303 34.1791 33.3485 34.2049 32.1023L34.2307 32.0492H36.7329C36.7329 32.5795 36.8618 33.0038 37.1198 33.3485C37.3778 33.6932 37.7131 33.8523 38.1 33.8523C38.5386 33.8523 38.8997 33.6667 39.1577 33.322C39.4156 32.9773 39.5446 32.5265 39.5446 31.9432C39.5446 31.2273 39.4156 30.697 39.1577 30.3523C38.8997 30.0076 38.5128 29.8485 38.0226 29.8485H36.7329V27.8333H36.7587Z" fill="#BDBDBD"/>
    <path d="M58.6592 30.697H59.7942V32.8182H58.6592V35.7879H56.0797V32.8182H51.6944L51.5654 31.1743L56.0797 22.2121H58.6592V30.697ZM53.9128 30.697H56.1055V26.0038H56.0539L55.9249 26.2689L53.9128 30.697Z" fill="#BDBDBD"/>
    <path d="M69.519 29.7689L70.1639 22.1856H76.6386V24.3599H72.2791L71.9954 27.5682C72.176 27.3826 72.4081 27.2235 72.6919 27.0909C72.9756 26.9583 73.2594 26.9053 73.5689 26.8788C74.6781 26.8523 75.5294 27.25 76.0969 28.0455C76.6902 28.8409 76.9739 29.9811 76.9739 31.4129C76.9739 32.7121 76.6386 33.7992 75.9679 34.6742C75.2972 35.5492 74.3428 35.9735 73.1046 35.9735C72.047 35.9735 71.1441 35.6288 70.3703 34.9394C69.5964 34.25 69.2353 33.2955 69.2611 32.1023L69.2869 32.0492L71.789 31.9167C71.789 32.5265 71.918 33.0038 72.1502 33.3485C72.3823 33.6932 72.7177 33.8523 73.1046 33.8523C73.5689 33.8523 73.8785 33.6402 74.0848 33.1894C74.2912 32.7386 74.3944 32.1553 74.3944 31.4394C74.3944 30.6439 74.2912 30.0076 74.059 29.5303C73.8269 29.053 73.4657 28.8409 73.0014 28.8409C72.6403 28.8409 72.3565 28.947 72.176 29.1326C71.9696 29.3447 71.8406 29.5833 71.789 29.928L69.519 29.7689Z" fill="#BDBDBD"/>
    <defs>
    <linearGradient id="paint0_linear" x1="51.5654" y1="7.00001" x2="59.7942" y2="7.00001" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="16.7915" y1="28.8939" x2="24.8153" y2="28.8939" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='matrix-options'" width="71" height="43" viewBox="0 0 71 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.66311 43C3.67522 43 0.430176 39.85 0.430176 35.9788C0.430176 32.1077 3.67522 28.9576 7.66311 28.9576C11.651 28.9576 14.896 32.1077 14.896 35.9788C14.896 39.85 11.651 43 7.66311 43ZM7.66311 30.5516C4.57445 30.5516 2.0527 32.9996 2.0527 35.9978C2.0527 38.996 4.57445 41.444 7.66311 41.444C10.7518 41.444 13.2735 38.996 13.2735 35.9978C13.2735 32.9996 10.7518 30.5516 7.66311 30.5516Z" fill="black"/>
    <path d="M22.1875 0H0V21.2533H22.1875V0Z" fill="#E3E3E3"/>
    <path d="M46.584 0H24.3965V21.2533H46.584V0Z" fill="url(#paint0_linear)"/>
    <path d="M71 0H48.8125V21.2533H71V0Z" fill="#E3E3E3"/>
    <path d="M26.0386 43C22.0507 43 18.8057 39.85 18.8057 35.9788C18.8057 32.1077 22.0507 28.9576 26.0386 28.9576C30.0265 28.9576 33.2715 32.1077 33.2715 35.9788C33.2715 39.85 30.0265 43 26.0386 43ZM26.0386 30.5516C22.9499 30.5516 20.4282 32.9996 20.4282 35.9978C20.4282 38.996 22.9499 41.444 26.0386 41.444C29.1272 41.444 31.649 38.996 31.649 35.9978C31.649 32.9996 29.1272 30.5516 26.0386 30.5516Z" fill="black"/>
    <path d="M26.0384 39.5843C28.0897 39.5843 29.7526 37.9701 29.7526 35.9788C29.7526 33.9876 28.0897 32.3734 26.0384 32.3734C23.9871 32.3734 22.3242 33.9876 22.3242 35.9788C22.3242 37.9701 23.9871 39.5843 26.0384 39.5843Z" fill="url(#paint1_linear)"/>
    <path d="M44.4141 43C40.4262 43 37.1812 39.85 37.1812 35.9788C37.1812 32.1077 40.4262 28.9576 44.4141 28.9576C48.402 28.9576 51.647 32.1077 51.647 35.9788C51.647 39.85 48.402 43 44.4141 43ZM44.4141 30.5516C41.3254 30.5516 38.8037 32.9996 38.8037 35.9978C38.8037 38.996 41.3254 41.444 44.4141 41.444C47.5027 41.444 50.0245 38.996 50.0245 35.9978C50.0245 32.9996 47.5027 30.5516 44.4141 30.5516Z" fill="black"/>
    <path d="M62.7896 43C58.8017 43 55.5566 39.85 55.5566 35.9788C55.5566 32.1077 58.8017 28.9576 62.7896 28.9576C66.7774 28.9576 70.0225 32.1077 70.0225 35.9788C70.0225 39.85 66.7774 43 62.7896 43ZM62.7896 30.5516C59.7009 30.5516 57.1792 32.9996 57.1792 35.9978C57.1792 38.996 59.7009 41.444 62.7896 41.444C65.8782 41.444 68.4 38.996 68.4 35.9978C68.4 32.9996 65.8782 30.5516 62.7896 30.5516Z" fill="black"/>
    <defs>
    <linearGradient id="paint0_linear" x1="24.3965" y1="10.6267" x2="46.584" y2="10.6267" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="22.3242" y1="35.9788" x2="29.7526" y2="35.9788" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='NUMBER'" width="84" height="27" viewBox="0 0 84 27" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M84 0H0V27H84V0Z" fill="#E3E3E3"/>
    <path d="M42.2444 3.76071H41.7075V23.2634H42.2444V3.76071Z" fill="black"/>
    <path d="M42.3171 3.20625V23.8179H41.6338V3.20625H42.3171ZM42.1463 22.7089V4.31518H41.7802V22.733H42.1463V22.7089Z" fill="black"/>
    <path d="M18.8644 20.8286H10.274V18.9241L14.2519 13.958C14.7888 13.2107 15.1549 12.5839 15.3745 12.0295C15.5942 11.475 15.7162 10.9929 15.7162 10.5348C15.7162 9.93214 15.5942 9.42589 15.3501 9.04017C15.1061 8.65446 14.7644 8.48571 14.3007 8.48571C13.8371 8.48571 13.471 8.70267 13.227 9.16071C12.9829 9.61874 12.8609 10.2214 12.8609 10.9446H10.1032L10.0788 10.8964C10.0544 9.59464 10.4204 8.48571 11.2014 7.59374C11.9823 6.67767 13.0073 6.24374 14.3252 6.24374C15.6674 6.24374 16.6924 6.62946 17.4489 7.40089C18.2055 8.17232 18.5715 9.20892 18.5715 10.5107C18.5715 11.4027 18.3519 12.1982 17.937 12.9696C17.5221 13.717 16.8388 14.6812 15.8626 15.9107L13.9591 18.5866H18.8644V20.8286Z" fill="url(#paint0_linear)"/>
    <path d="M27.9671 15.4286H29.2361V17.6705H27.9671V20.8286H25.1362V17.6705H20.3041L20.1577 15.9348L25.1362 6.46071H27.9671V15.4286ZM22.7446 15.4286H25.1362V10.4625H25.0874L24.941 10.7277L22.7446 15.4286Z" fill="url(#paint1_linear)"/>
    <path d="M30.9199 14.4643L31.6277 6.4366H38.7537V8.72678H33.9461L33.6288 12.1018C33.824 11.9089 34.0925 11.7402 34.4097 11.5955C34.727 11.4509 35.0443 11.3786 35.3859 11.3786C36.6061 11.3545 37.5335 11.7643 38.168 12.608C38.8025 13.4518 39.1442 14.633 39.1442 16.1759C39.1442 17.55 38.7781 18.683 38.046 19.6232C37.3139 20.5393 36.2645 20.9973 34.8978 20.9973C33.7264 20.9973 32.7258 20.6357 31.8961 19.8884C31.0419 19.1652 30.6515 18.1527 30.6759 16.8991L30.7003 16.8509L33.458 16.7062C33.458 17.3571 33.58 17.8634 33.8484 18.2009C34.1169 18.5625 34.4586 18.7312 34.8978 18.7312C35.3859 18.7312 35.752 18.4902 35.996 18.0321C36.2157 17.55 36.3377 16.9473 36.3377 16.1759C36.3377 15.3321 36.2157 14.6571 35.9472 14.1509C35.7032 13.6687 35.3127 13.4036 34.7758 13.4036C34.3853 13.4036 34.0681 13.5 33.8729 13.717C33.6532 13.9339 33.5068 14.1991 33.4336 14.5607L30.9199 14.4643Z" fill="url(#paint2_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="10.0776" y1="13.5362" x2="18.8644" y2="13.5362" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="20.1577" y1="13.6446" x2="29.2361" y2="13.6446" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="30.6748" y1="13.717" x2="39.1442" y2="13.717" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
  <svg v-if="name=='MULTINUMBER'" width="67" height="39" viewBox="0 0 67 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M66.1421 0H0V17.6842H66.1421V0Z" fill="#E3E3E3"/>
    <path d="M6.82102 9.47368L7.27892 4.21578H11.8894V5.71578H8.79471L8.58944 7.92631C8.71576 7.79999 8.88944 7.68946 9.09471 7.59473C9.29997 7.49999 9.50523 7.45262 9.72629 7.45262C10.5158 7.43683 11.1158 7.70525 11.5263 8.25789C11.9368 8.81052 12.1579 9.5842 12.1579 10.5947C12.1579 11.4947 11.921 12.2368 11.4473 12.8526C10.9737 13.4526 10.2947 13.7526 9.4105 13.7526C8.6526 13.7526 8.00523 13.5158 7.46839 13.0263C6.91576 12.5526 6.66313 11.8895 6.67892 11.0684L6.69471 11.0368L8.47892 10.9421C8.47892 11.3684 8.55787 11.7 8.73155 11.921C8.90523 12.1579 9.12629 12.2684 9.4105 12.2684C9.72629 12.2684 9.96313 12.1105 10.121 11.8105C10.2631 11.4947 10.3421 11.1 10.3421 10.5947C10.3421 10.0421 10.2631 9.59999 10.0894 9.26841C9.93155 8.95262 9.67892 8.77894 9.33155 8.77894C9.07892 8.77894 8.87366 8.8421 8.74734 8.9842C8.60523 9.12631 8.5105 9.29999 8.46313 9.53683L6.82102 9.47368Z" fill="url(#paint0_linear)"/>
    <path d="M18.8051 13.6421H13.2472V12.3947L15.8209 9.1421C16.1682 8.65263 16.4051 8.2421 16.5472 7.87894C16.6893 7.51579 16.7682 7.2 16.7682 6.9C16.7682 6.50526 16.6893 6.17368 16.5314 5.92105C16.3735 5.66842 16.1524 5.55789 15.8524 5.55789C15.5524 5.55789 15.3156 5.7 15.1577 6C14.9998 6.3 14.9209 6.69473 14.9209 7.16842H13.1366L13.1209 7.13684C13.1051 6.28421 13.3419 5.55789 13.8472 4.97368C14.3524 4.37368 15.0156 4.08947 15.8682 4.08947C16.7366 4.08947 17.3998 4.3421 17.8893 4.84736C18.3788 5.35263 18.6156 6.03157 18.6156 6.88421C18.6156 7.46842 18.4735 7.98947 18.2051 8.49473C17.9366 8.98421 17.4788 9.63157 16.863 10.421L15.6314 12.1737H18.8051V13.6421Z" fill="url(#paint1_linear)"/>
    <path d="M66.1421 21.3158H0V39H66.1421V21.3158Z" fill="#E3E3E3"/>
    <path d="M27.3313 23.7789H26.9839V36.5526H27.3313V23.7789Z" fill="black"/>
    <path d="M27.3786 23.4158V36.9158H26.9365V23.4158H27.3786ZM27.2681 36.1895V24.1263H27.0313V36.1895H27.2681Z" fill="black"/>
    <path d="M11.5106 31.421H12.3159V32.8895H11.4949V34.9579H9.67906V32.8895H6.55274L6.45801 31.7526L9.67906 25.5474H11.5106V31.421ZM8.13169 31.421H9.67906V28.1684H9.64748L9.55274 28.3421L8.13169 31.421Z" fill="url(#paint2_linear)"/>
    <path d="M18.5844 28.0421C18.5844 28.5158 18.4739 28.9421 18.2686 29.2895C18.0476 29.6526 17.7633 29.9368 17.4002 30.1421C17.8265 30.3632 18.1581 30.6632 18.3949 31.0421C18.6318 31.4368 18.7581 31.8789 18.7581 32.3842C18.7581 33.2526 18.5055 33.9316 18.0002 34.3895C17.4949 34.8474 16.8318 35.0842 16.0107 35.0842C15.1897 35.0842 14.5265 34.8474 14.0055 34.3895C13.5002 33.9158 13.2476 33.2526 13.2476 32.3842C13.2476 31.8789 13.3739 31.4368 13.6107 31.0421C13.8476 30.6474 14.1791 30.3474 14.6055 30.1421C14.2265 29.9368 13.9423 29.6526 13.7212 29.2895C13.516 28.9263 13.4055 28.5158 13.4055 28.0421C13.4055 27.2053 13.6423 26.5579 14.116 26.1C14.5897 25.6421 15.2212 25.4053 15.9949 25.4053C16.7686 25.4053 17.4002 25.6421 17.8739 26.1C18.3476 26.5579 18.5844 27.2053 18.5844 28.0421ZM16.9107 32.2421C16.9107 31.8158 16.8318 31.4842 16.6581 31.2316C16.4844 30.9789 16.2633 30.8526 15.9949 30.8526C15.7107 30.8526 15.4897 30.9789 15.3318 31.2316C15.1739 31.4842 15.0791 31.8158 15.0791 32.2421C15.0791 32.6684 15.1581 33.0158 15.3318 33.2526C15.4897 33.4895 15.7265 33.6158 16.0107 33.6158C16.2791 33.6158 16.5002 33.4895 16.6739 33.2526C16.8318 33.0158 16.9107 32.6842 16.9107 32.2421ZM16.737 28.121C16.737 27.7421 16.6739 27.4263 16.5476 27.2053C16.4212 26.9842 16.2318 26.8737 15.9949 26.8737C15.7581 26.8737 15.5686 26.9842 15.4423 27.2053C15.316 27.4263 15.2528 27.7263 15.2528 28.121C15.2528 28.5158 15.316 28.8316 15.4423 29.0526C15.5686 29.2737 15.7581 29.4 15.9949 29.4C16.2318 29.4 16.4212 29.2895 16.5476 29.0684C16.6739 28.8316 16.737 28.5316 16.737 28.121Z" fill="url(#paint3_linear)"/>
    <path d="M25.1845 28.0421C25.1845 28.5158 25.074 28.9421 24.8687 29.2895C24.6476 29.6526 24.3634 29.9368 24.0003 30.1421C24.4266 30.3632 24.7582 30.6632 24.995 31.0421C25.2319 31.4368 25.3582 31.8789 25.3582 32.3842C25.3582 33.2526 25.1055 33.9316 24.6003 34.3895C24.095 34.8474 23.4319 35.0842 22.6108 35.0842C21.7898 35.0842 21.1266 34.8474 20.6056 34.3895C20.1003 33.9158 19.8477 33.2526 19.8477 32.3842C19.8477 31.8789 19.974 31.4368 20.2108 31.0421C20.4477 30.6474 20.7792 30.3474 21.2055 30.1421C20.8266 29.9368 20.5424 29.6526 20.3213 29.2895C20.1161 28.9263 20.0056 28.5158 20.0056 28.0421C20.0056 27.2053 20.2424 26.5579 20.7161 26.1C21.1898 25.6421 21.8213 25.4053 22.595 25.4053C23.3687 25.4053 24.0003 25.6421 24.474 26.1C24.9319 26.5579 25.1845 27.2053 25.1845 28.0421ZM23.495 32.2421C23.495 31.8158 23.4161 31.4842 23.2424 31.2316C23.0687 30.9789 22.8477 30.8526 22.5792 30.8526C22.295 30.8526 22.074 30.9789 21.9161 31.2316C21.7582 31.4842 21.6634 31.8158 21.6634 32.2421C21.6634 32.6684 21.7424 33.0158 21.9161 33.2526C22.074 33.4895 22.3108 33.6158 22.595 33.6158C22.8634 33.6158 23.0845 33.4895 23.2582 33.2526C23.4161 33.0158 23.495 32.6842 23.495 32.2421ZM23.3213 28.121C23.3213 27.7421 23.2582 27.4263 23.1319 27.2053C23.0055 26.9842 22.8161 26.8737 22.5792 26.8737C22.3424 26.8737 22.1529 26.9842 22.0266 27.2053C21.9003 27.4263 21.8371 27.7263 21.8371 28.121C21.8371 28.5158 21.9003 28.8316 22.0266 29.0526C22.1529 29.2737 22.3424 29.4 22.5792 29.4C22.8161 29.4 23.0055 29.2895 23.1319 29.0684C23.2582 28.8316 23.3213 28.5316 23.3213 28.121Z" fill="url(#paint4_linear)"/>
    <defs>
    <linearGradient id="paint0_linear" x1="6.67822" y1="8.9842" x2="12.1579" y2="8.9842" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint1_linear" x1="13.1201" y1="8.86579" x2="18.8051" y2="8.86579" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint2_linear" x1="6.45801" y1="30.2526" x2="12.3159" y2="30.2526" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint3_linear" x1="13.2476" y1="30.2447" x2="18.7581" y2="30.2447" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    <linearGradient id="paint4_linear" x1="19.8477" y1="30.2447" x2="25.3582" y2="30.2447" gradientUnits="userSpaceOnUse">
    <stop stop-color="#FF1564"/>
    <stop offset="1" stop-color="#FD7328"/>
    </linearGradient>
    </defs>
  </svg>
</div>
</template>

<script>
  export default {
    name: 'activitySVG',
    props: {
      name:{
        type:String,
      },
    },
  }
</script>

<style scoped lang="scss">

</style>
