import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "RangeSlider--Actions"
}
const _hoisted_2 = { class: "ErrorContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue_slider = _resolveComponent("vue-slider")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", {
      class: _normalizeClass(["RangeSlider", {'RangeSlider--ShowTooltip': _ctx.showTooltip}])
    }, [
      _createVNode(_component_vue_slider, {
        marks: _ctx.marks,
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        min: _ctx.minValue,
        max: _ctx.maxValue,
        "tooltip-formatter": _ctx.tooltipFormat,
        onChange: _ctx.onValueChange,
        interval: _ctx.interval
      }, null, 8, ["marks", "modelValue", "min", "max", "tooltip-formatter", "onChange", "interval"]),
      (_ctx.showActions)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("span", {
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.addStep && _ctx.addStep(...args))),
              class: _normalizeClass({ disabled: _ctx.value == _ctx.maxValue })
            }, "+", 2),
            _createElementVNode("span", {
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.restStep && _ctx.restStep(...args))),
              class: _normalizeClass({ disabled: _ctx.value == _ctx.minValue })
            }, "-", 2)
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("span", null, _toDisplayString(_ctx.errorMessage), 513), [
          [_vShow, _ctx.hasError]
        ])
      ])
    ], 2),
    _cache[3] || (_cache[3] = _createElementVNode("br", null, null, -1))
  ], 64))
}