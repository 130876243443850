<template>
<div class="QuestionCreation">
  <div class="QuestionCreation">
    <div class="QuestionCreation--New" v-on:click="displayQuestionTypes()">
      <p>{{ isPollActivity && questions.length > 0? $t('action_edit') : $t('activity_question_creation_add')}}</p>
      <div :class="{'displayedQuestionTypes':showQuestionTypes}">
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0)">
        <path d="M16.8386 8.73873H9.26119V1.16131C9.26119 1.01702 9.1442 0.900024 8.9999 0.900024C8.85561 0.900024 8.73861 1.01702 8.73861 1.16131V8.73873H1.16119C1.0169 8.73873 0.899902 8.85573 0.899902 9.00002C0.899902 9.14432 1.0169 9.26132 1.16119 9.26132H8.73861V16.8387C8.73861 16.983 8.85561 17.1 8.9999 17.1C9.1442 17.1 9.26119 16.983 9.26119 16.8387V9.26132H16.8386C16.9829 9.26132 17.0999 9.14432 17.0999 9.00002C17.0999 8.85573 16.9829 8.73873 16.8386 8.73873Z" fill="#828282"/>
        </g>
        <defs>
        <clipPath id="clip0">
        <rect width="16.2" height="16.2" fill="white" transform="translate(0.899902 0.900024)"/>
        </clipPath>
        </defs>
        </svg>
      </div>
    </div>
    <transition name="list">
      <div class="QuestionCreation__QuestionTypes" v-show="showQuestionTypes">
        <p class="QuestionCreation__QuestionTypes--Title">
          {{$t('activity_question_type')}}
        </p>
        <QuestionsGrid :isPollActivity="isPollActivity" class="QuestionCreation__QuestionTypes--List" directSelection v-on:select-type="add($event)"/>
      </div>
    </transition>
  </div>
</div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import QuestionSVG from '~/components/Question/QuestionSVG'
  import QuestionsGrid from '~/components/SurveyCreation/QuestionsGrid.vue'
  export default {
    name: 'QuestionCreation',
    computed: {
      ...mapGetters([
        'theme',
      ]),
    },
    components:{
      QuestionSVG,
      QuestionsGrid
    },
    props: {
      questions:{
        type:Array,
        default:() => []
      },
      isPollActivity:{
        type:Boolean,
        default:false,
      }
    },
    data(){
      return{
        showQuestionTypes:false,
      }
    },
    methods:{
      add(type){
        this.$emit('add',type)
      },
      displayQuestionTypes(){
        this.showQuestionTypes = !this.showQuestionTypes
      },
    },
  }
</script>

<style scoped lang="scss">
.QuestionCreation{
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  &--New{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    cursor: pointer;
    width: 100%;
    div:first-of-type{
      width: 54px;
      height: 54px;
      border: 2px solid var(--accent-color);
      box-sizing: border-box;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #F7F4F8;
      transition:0.5s;
    }
    p{
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #3E3E3E;
      margin-bottom: 11px;
    }
  }
  &__QuestionTypes{
    background: #F5F5F5;
    border: 1px solid #D3D3D3;
    width: 100%;
    position:relative;
    min-height: 100px;
    margin-top: 23px;
    &::before{
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      left: calc(50% - 10px);
      top: -25px;
      display: block;
      border: 10px outset transparent;
      border-bottom: 15px solid #F5F5F5;
    }
    &--Title{
      margin: 30px 0;
      font-size: 19px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #3E3E3E;
      width: 100%;
    }
    &--List{
      margin: 0 50px 50px 50px;
    }
  }
}
.displayedQuestionTypes{
  background: var(--accent-color)!important;
  transform: rotate(135deg);
  transition:0.5s;
  svg{
    fill: white;
    width: 25px;
    height: 25px;
    path{
      fill: white;
    }
  }
}
.list-enter,
.list-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}

.list-enter-active,
.list-leave-active {
  transition: all 0.3s;
}
</style>
