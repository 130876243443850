<template>
  <div class="WatUxDataCreation">
    <div>
      {{ $t("watux_creation_info") }}
    </div>
    <Separator />
    <div class="WatUxDataCreation--UxData">
      <CommunityInput
        type="text"
        required
        :placeholder="$t('watux_creation_initial_url')"
        v-model="uxData.initialUrl"
        hideErrorMessage
      />
      <div class="WatUxDataCreation--Switches">
        <SwitchInput
          :text="$t('watux_creation_record_screen')"
          v-model="uxData.recordScreen"
        />
        <SwitchInput
          :text="$t('watux_creation_record_micro')"
          v-model="uxData.recordMicrophone"
        />
        <SwitchInput
          :text="$t('watux_creation_record_camera')"
          v-model="uxData.recordWebcam"
        />
      </div>
    </div>
    <Separator v-show="checkpoints.length > 0" />
    <div class="WatUxDataCreation--Checkpoints" v-show="checkpoints.length > 0">
      <h1>{{ $t("watux_creation_event") }}</h1>
      <div
        class="WatUxDataCreation--CheckpointsList"
        :class="{ hasCheckpoints: checkpoints.length > 1 }"
      >
        <div
          class="WatUxDataCreation--Checkpoint"
          v-for="(cp, index) in checkpoints"
        >
          <div class="WatUxDataCreation--CheckpointConfig">
            <select v-model="cp.type">
              <option :value="null" disabled selected hidden>
                Tipo de evento
              </option>
              <option value="CLICK">
                {{ $t("watux_creation_event_click") }}
              </option>
              <option value="CHANGE">
                {{ $t("watux_creation_event_change") }}
              </option>
              <option value="URL_LOAD">
                {{ $t("watux_creation_event_url_load") }}
              </option>
              <option value="SCROLL">
                {{ $t("watux_creation_event_scroll") }}
              </option>
              <option value="VIEW">
                {{ $t("watux_creation_event_view") }}
              </option>
            </select>
            <CommunityInput
              type="text"
              ref="name"
              required
              :placeholder="$t('watux_creation_event_name')"
              v-model="cp.name"
              hideErrorMessage
            />
            <CommunityInput
              type="text"
              ref="message"
              required
              :placeholder="$t('watux_creation_event_description')"
              v-model="cp.message"
              hideErrorMessage
            />
            <CommunityInput
              type="text"
              ref="elementSelector"
              required
              :placeholder="$t('watux_creation_html_selector')"
              v-model="cp.elementSelector"
              v-show="cp.type != 'URL_LOAD'"
              hideErrorMessage
            />
            <CommunityInput
              type="text"
              ref="checkUrl"
              required
              placeholder="Url"
              v-model="cp.checkUrl"
              v-show="cp.type == 'URL_LOAD'"
              hideErrorMessage
            />
          </div>
          <div class="WatUxDataCreation--Actions">
            <div class="WatUxDataCreation--Actions--Remove">
              <svg
                width="23"
                height="23"
                viewBox="0 0 23 23"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                v-on:click="$emit('delete', index)"
              >
                <g clip-path="url(#clip0)">
                  <path
                    d="M19.1053 2.3071H14.997V0.750016C14.997 0.335836 14.6612 0 14.247 0H8.75338C8.3392 0 8.00337 0.335791 8.00337 0.750016V2.3071H3.89504C3.48086 2.3071 3.14502 2.64289 3.14502 3.05711V5.36417C3.14502 5.77835 3.48081 6.11418 3.89504 6.11418H4.12288L4.80839 22.2818C4.82537 22.6833 5.15586 23 5.55768 23H17.4426C17.8444 23 18.1749 22.6833 18.1919 22.2818L18.8773 6.11414H19.1052C19.5194 6.11414 19.8552 5.77835 19.8552 5.36412V3.05711C19.8553 2.64284 19.5195 2.3071 19.1053 2.3071ZM9.5034 1.49999H13.497V2.3071H9.5034V1.49999ZM16.7238 21.5H6.27657L5.62421 6.11418H17.376L16.7238 21.5ZM18.3553 4.61415C17.6738 4.61415 5.0533 4.61415 4.64505 4.61415V3.80708H18.3553V4.61415Z"
                    fill="#BDBDBD"
                  />
                  <path
                    d="M8.48293 8.41406C8.06875 8.41406 7.73291 8.74985 7.73291 9.16408V18.4501C7.73291 18.8642 8.0687 19.2001 8.48293 19.2001C8.89711 19.2001 9.23294 18.8643 9.23294 18.4501V9.16408C9.2329 8.74985 8.89711 8.41406 8.48293 8.41406Z"
                    fill="#BDBDBD"
                  />
                  <path
                    d="M11.5 8.41406C11.0858 8.41406 10.75 8.74985 10.75 9.16408V18.4501C10.75 18.8642 11.0857 19.2001 11.5 19.2001C11.9142 19.2001 12.25 18.8643 12.25 18.4501V9.16408C12.25 8.74985 11.9142 8.41406 11.5 8.41406Z"
                    fill="#BDBDBD"
                  />
                  <path
                    d="M14.5171 8.41406C14.1029 8.41406 13.7671 8.74985 13.7671 9.16408V18.4501C13.7671 18.8642 14.1029 19.2001 14.5171 19.2001C14.9313 19.2001 15.2671 18.8643 15.2671 18.4501V9.16408C15.2671 8.74985 14.9313 8.41406 14.5171 8.41406Z"
                    fill="#BDBDBD"
                  />
                </g>
                <defs>
                  <clipPath id="clip0">
                    <rect width="23" height="23" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div
              class="WatUxDataCreation--Actions--Info"
              v-on:click="showUxCreationInfo()"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                width="23"
                height="23"
                fill="grey"
              >
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="WatUxDataCreation--New" v-on:click="add()">
      <div>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0)">
            <path
              d="M16.8386 8.73873H9.26119V1.16131C9.26119 1.01702 9.1442 0.900024 8.9999 0.900024C8.85561 0.900024 8.73861 1.01702 8.73861 1.16131V8.73873H1.16119C1.0169 8.73873 0.899902 8.85573 0.899902 9.00002C0.899902 9.14432 1.0169 9.26132 1.16119 9.26132H8.73861V16.8387C8.73861 16.983 8.85561 17.1 8.9999 17.1C9.1442 17.1 9.26119 16.983 9.26119 16.8387V9.26132H16.8386C16.9829 9.26132 17.0999 9.14432 17.0999 9.00002C17.0999 8.85573 16.9829 8.73873 16.8386 8.73873Z"
              fill="#828282"
            />
          </g>
          <defs>
            <clipPath id="clip0">
              <rect
                width="16.2"
                height="16.2"
                fill="white"
                transform="translate(0.899902 0.900024)"
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <p>{{ $t("watux_creation_add") }}</p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import Separator from "@/components/Separator";
import CommunityInput from "@/components/CommunityInput.vue";
import SwitchInput from "~/components/SwitchInput/SwitchInput";
import Swal from "sweetalert2";
export default {
  name: "TopicsCreation",
  components: {
    Separator,
    CommunityInput,
    SwitchInput,
  },
  props: {
    uxData: {
      type: Object,
      default: () => {},
    },
    checkpoints: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapGetters(["communityCurrentActivityId", "communityId"]),
  },
  data() {
    return {
      errors: [],
    };
  },
  methods: {
    add() {
      const checkPoint = {
        checkId: this.getId(),
        name: "",
        message: "",
        elementSelector: "",
        type: null,
        checkUrl: "",
      };
      this.$emit("add", checkPoint);
    },
    getId() {
      let id = 0;
      for (let cp of this.checkpoints) {
        const extractId = parseInt(
          cp.checkId.substring(cp.checkId.lastIndexOf("c") + 1)
        );
        if (extractId >= id) id = extractId + 1;
      }
      return (
        "c" +
        this.communityId +
        "a" +
        this.communityCurrentActivityId +
        "c" +
        id
      );
    },
    isValid() {
      let isValid = true;
      let inputsValid = true;
      this.errors = [];
      if (!this.isValidUrl(this.uxData.initialUrl)) {
        this.errors.push("Introduce una URL correcta");
        isValid = false;
      }
      for (let i in this.checkpoints) {
        if (this.checkpoints[i].type == null) {
          this.errors.push(
            "Selecciona un tipo en el evento " + (parseInt(i) + 1)
          );
          isValid = false;
        }
        if (this.$refs.name[i].validate()) inputsValid = false;
        if (this.$refs.message[i].validate()) inputsValid = false;
        if (
          this.checkpoints[i].type != "URL_LOAD" &&
          this.$refs.elementSelector[i].validate()
        )
          inputsValid = false;
        if (
          this.checkpoints[i].type == "URL_LOAD" &&
          this.$refs.checkUrl[i].validate()
        )
          inputsValid = false;
      }
      if (!inputsValid) {
        this.errors.push("Completa los campos que faltan");
        isValid = false;
      }
      return isValid;
    },
    isValidUrl(str) {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // fragment locator
      return !!pattern.test(str);
    },
    showUxCreationInfo() {
      Swal.fire({
        html: `<div>
        <h1>Existen dos formas de referenciar a una etiqueta html (texto, botón, imagen...):</h1>
        <ul>
            <li>
                <h2>Mediante un <span>id(#)</span>:</h2>
                <p>Los id sirven para identificar a un elemento de forma única y se referencia añadiendo '<span>#</span>' antes del nombre del identificador: </p>
                <b><span>#</span>nombre_del_id</b>
            </li>
            <li>
                <h2>Mediante una <span>clase(.)</span>:</h2>
                <p>Las clases sirven para identificar a un grupo de elementos y se referencia añadiendo '<span>.</span>' antes del nombre de la clase: </p>
                <b><span>.</span>nombre_de_la_clase</b>
            </li>
            <li>
                <h2>Referenciando al tipo de <span>elemento</span>:</h2>
                <p>Hay varios tipos elementos(etiquetas) html según su utilidad <span>(div,h1,img,button)</span> y se pueden referenciar a todos los elementos de un tipo escribiendo el nombre del tipo: </p>
                <b><span>img</span></b>
            </li>
        </ul>
    </div>`,
        customClass: {
          htmlContainer: "uxCreationInfoHtml",
          popup: "uxCreationInfoPopUp",
        },
      });
    },
  },
};
</script>
<style lang="scss">
.uxCreationInfoHtml {
  h1 {
    color: black;
    margin-bottom: 20px;
  }
  h2 {
    width: fit-content;
    margin-top: 10px;
    color: black;
  }
  p {
    text-align: start;
    margin-left: 20px;
    margin-top: 10px;
  }
  b {
    font-size: 20px;
  }
  span {
    font-size: 24px;
    font-weight: 600;
    background: -webkit-linear-gradient(#FF1564, #FD7328);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
.uxCreationInfoPopUp {
  width: 800px;
}
.WatUxDataCreation {
  input {
    background: #f5f5f5 !important;
  }
  &--Checkpoint {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto 30px;
    background: #ffffff;
    border: 1px solid #e1e1e1;
    filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
    padding: 20px;
    input,
    select {
      font-size: 1rem !important;
      padding: 4px 12px !important;
    }
  }
}
</style>
<style scoped lang="scss">
.WatUxDataCreation {
  display: flex;
  flex-direction: column;
  h1 {
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 19px;
    color: #3e3e3e;
    margin-bottom: 10px;
  }
  select {
    width: 100%;
    outline: none;
    background: #f5f5f5;
    border: 1px solid #d3d3d3;
    &:invalid {
      color: #969696;
      opacity: 0.6;
    }
  }
  &--Switches,
  &--UxData {
    display: grid;
    grid-gap: 10px;
  }
  &--CheckpointsList {
    display: grid;
    grid-template-columns: 50%;
    grid-gap: 15px;
    &.hasCheckpoints {
      grid-template-columns: auto auto;
    }
    @media only screen  and (max-width:600px) {
      display: block;
      margin-bottom: 10px;
    }
  }
  &--CheckpointConfig {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: auto auto;
    @media only screen  and (max-width:600px) {
      display: block;
      margin-bottom: 10px;
      margin-bottom: 2px;
    }
  }
  &--Actions {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr;
    row-gap: 10px;
    &--Remove {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      svg {
        &:hover {
          cursor: pointer;
          path {
            fill: var(--primary-color);
          }
        }
      }
    }
    &--Info {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      cursor: pointer;
      svg {
        &:hover {
          path {
            fill: rgb(0, 0, 0);
          }
        }
      }
    }
  }
  &--New {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #f7f4f8;
    padding: 20px 0;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    div {
      width: 54px;
      height: 54px;
      background: #ffffff;
      border: 2px solid var(--accent-color);
      box-sizing: border-box;
      border-radius: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    p {
      font-family: Roboto Condensed;
      font-style: normal;
      font-weight: normal;
      font-size: 19px;
      text-align: center;
      letter-spacing: -0.01em;
      color: #3e3e3e;
      margin-top: 11px;
    }
  }
}
</style>
