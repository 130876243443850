<template>
<div class="NavigationRules" :class="{'general':question == undefined}">
  <button class="add-button" type="button" name="button" v-on:click="addRule()">
    <activitySVG :name="'plus-icon'"/>
    {{$t('activity_navigation_rules_add')}}
  </button>
  <div class="NavigationRules--Wrapper">
    <div v-for="(r,i) in rules">
      <div :key="conditionKey">
        <div class="NavigationRules--Condition" :class="{'isMatrix':isMatrixQuestion(c.qId)}" v-for="(c,condIndex) in r.conditions">
          <select class="operator" v-model="r.conditions[condIndex-1].operator" v-if="condIndex > 0" v-on:change="changeConditionOperator(i,r.conditions[condIndex-1].operator)">
            <option value="&">{{$t('and')}}</option>
            <option value="|">{{$t('or')}}</option>
          </select>
          <p v-if="question == undefined">{{$t('activity_navigation_rules_condition')}} </p>
          <select v-if="question == undefined" v-model="c.qId" v-on:change="conditionKey++; changeRule()">
            <option :value="q.id" v-for="q in questionList">{{q.order}}. {{q.text}}</option>
          </select>
          <p v-if="question == undefined">{{$t('activity_navigation_rules_condition_answer2')}} </p>
          <p v-if="question != undefined && condIndex == 0">{{$t('activity_navigation_rules_condition_answer')}}</p>
          <select v-model="c.equality" v-on:change="conditionKey++; changeRule()">
            <option value="=">{{$t('activity_navigation_rules_option_equal')}}</option>
            <option value="!=">{{$t('activity_navigation_rules_option_diferent')}}</option>
            <option value="==">{{$t('activity_navigation_rules_option_only_value')}}</option>
            <option value="#">{{$t('activity_navigation_rules_option_count')}}</option>
          </select>
          <select v-model="c.valId" v-on:change="changeRule()" v-if="c.equality != '#' && !isImageQuestion(c.qId)" :class="{'error':errors.includes('rulecondition-'+i+'-'+condIndex)}">
            <option :value="v.id" v-for="v in getQuestionValues(c.qId)">{{v.value}}</option>
          </select>
          <select v-model="c.valId" v-on:change="changeRule()" v-if="c.equality != '#' && isImageQuestion(c.qId)" :class="{'error':errors.includes('rulecondition-'+i+'-'+condIndex)}">
            <option :value="v.id" v-for="v in getQuestionValues(c.qId)">{{v.id}}</option>
          </select>
          <select v-model="c.valId" v-on:change="changeRule()" v-if="c.equality == '#'" :class="{'error':errors.includes('rulecondition-'+i+'-'+condIndex)}">
            <option :value="x" v-for="x in getQuestionValues(c.qId).length">{{x}}</option>
          </select>
          <select v-model="c.optionId" v-on:change="changeRule()" v-if="c.equality != '#' && isMatrixQuestion(c.qId) && !isMatrixQuestionRadioButton(c.qId)">
            <option :value="x" v-for="(index, x) in parseInt(getQuestionById(c.qId).rankingMax)+1" v-show="x<=getQuestionById(c.qId).rankingMax && x>=getQuestionById(c.qId).rankingMin">{{x}}</option>
          </select>
          <select v-model="c.optionId" v-on:change="changeRule()" v-if="c.equality != '#' && isMatrixQuestion(c.qId) && isMatrixQuestionRadioButton(c.qId)">
            <option :value="index" v-for="(value, index) in getQuestionById(c.qId).radioOptionTexts">{{value}}</option>
          </select>
          <activitySVG class="condition-remove" :name="'remove-icon'" v-on:click.native="removeCondition(i, condIndex)" />
        </div>
      </div>

      <div class="NavigationRules--AddCondition">
        <div class="NavigationRules--AddConditionIcon" v-on:click="addCondition(i)" :data-title="$t('add_condition')">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.1064 5.8298H6.17031V0.89375C6.17031 0.799752 6.0941 0.723541 6.0001 0.723541C5.90611 0.723541 5.8299 0.799752 5.8299 0.89375V5.8298H0.893842C0.799844 5.8298 0.723633 5.90601 0.723633 6.00001C0.723633 6.09401 0.799844 6.17022 0.893842 6.17022H5.8299V11.1063C5.8299 11.2003 5.90611 11.2765 6.0001 11.2765C6.0941 11.2765 6.17031 11.2003 6.17031 11.1063V6.17022H11.1064C11.2004 6.17022 11.2766 6.09401 11.2766 6.00001C11.2766 5.90601 11.2004 5.8298 11.1064 5.8298Z" fill="#3E3E3E"/>
          </svg>
        </div>
        <div class="NavigationRules--AddConditionLine">
        </div>
      </div>
      <div class="NavigationRules--Action" :class="{'cond-show-option':r.action.type == 'cond-show-option'}" :key="actionKey">
        <div class="NavigationRules--ActionText">
          {{$t('activity_navigation_rules_action')}}
        </div>
        <select v-model="r.action.type" v-on:change="actionKey++;changeRule()">
          <option value="jump-to">{{$t('activity_navigation_rules_action_jump')}}</option>
          <option value="killer">{{$t('activity_navigation_rules_action_killer')}}</option>
          <option value="quality-killer">{{$t('activity_navigation_rules_action_quality')}}</option>
          <option value="quality-killer-nosanction">{{$t('activity_navigation_rules_action_quality_nosanction')}}</option>
          <option value="finish-test">{{$t('activity_navigation_rules_action_finished')}}</option>
          <option value="cond-show">{{$t('activity_navigation_rules_action_show_question')}}</option>
          <option value="cond-show-option">{{$t('activity_navigation_rules_action_show_in_question')}}</option>
        </select>
        <select v-model="r.action.qId" v-on:change="changeRule()" v-if="showRuleQuestionList(r.action.type)" :class="{'error':errors.includes('rulequestion-'+i)}">
          <option v-show="showQuestionByActionType(q,r)" :value="q.id" v-for="q in questionList">{{q.order}}. {{q.text}}</option>
        </select>
        <p v-if="(r.action.qId != undefined || r.action.qId != null) && showRuleValues(r.action.type)">{{$t('activity_navigation_rules_action_answer_option')}}</p>
        <select v-if="(r.action.qId != undefined || r.action.qId != null) && showRuleValues(r.action.type)" v-model="r.action.valId" v-on:change="changeRule()" :class="{'error':errors.includes('ruleval-'+i)}">
          <option :value="v.id" v-for="v in getQuestionById(r.action.qId).values">{{v.value}}</option>
        </select>
      </div>
      <div class="Separator">

      </div>
    </div>
  </div>
</div>
</template>

<script>
  import activitySVG from '../ActivitySVG/activitySVG.vue'
  import Swal from 'sweetalert2'
  export default {
    name: 'NavigationRules',
    emits:['add-rule', 'remove-rule', 'change-rule'],
    components:{
      activitySVG
    },
    props: {
      rules:{
        type:Array,
      },
      questionList:Array,
      question:{
        type:Object,
        default: undefined
      },
    },
    data(){
      return{
        errors:[],
        conditionKey:100,
        actionKey:0,
      }
    },
    methods:{
      addRule(){
        if(this.questionList.length == 0){
          Swal.fire({
            icon: 'info',
            text: 'Añade al menos una pregunta'
          })
          return
        }
        this.$emit('add-rule')
      },
      changeRule(){
        this.$emit('change-rule')
      },
      addCondition(index){
        let qId = this.question != undefined ? this.question.id : this.questionList[0].id
        let operator = this.rules[index].conditions.length > 0 ? "&" : null
        this.rules[index].conditions[this.rules[index].conditions.length-1].operator = operator
        this.rules[index].conditions.push({equality:"=",operator:null,taskNum:this.rules[index].action.taskNum,qId:qId,valId:0})
        this.conditionKey++
      },
      changeConditionOperator(index, operator){
        for(let c of this.rules[index].conditions){
          if(c.operator != null) c.operator = operator
        }
        this.conditionKey++
        this.changeRule()
      },
      removeCondition(ruleIndex,conditionIndex){
        this.$emit('remove-rule', ruleIndex)
        if(this.$parent.$parent.rules[ruleIndex].conditions.length == 1) this.$parent.$parent.rules.splice(ruleIndex,1)
        else this.$parent.$parent.rules[ruleIndex].conditions.splice(conditionIndex,1)
        this.conditionKey++
        this.changeRule()
      },
      getQuestionValues(id){
        let q = this.getQuestionById(id)
        if (q.type != "RANKING" && q.type != "RATING_FACES" && q.type != "IMAGE_CHOICE" && q.type != "MULTIPLEIMAGE_CHOICE") return q.values
        if (q.type == "IMAGE_CHOICE" || q.type == "MULTIPLEIMAGE_CHOICE") return q.imageValues
        let array = []
        for(let min = q.rankingMin; min<=q.rankingMax;min++){
          array.push({value:min,id:min})
        }
        return array
      },
      getQuestionById: function(id){
        for(let q of this.questionList){
          if(q.id == id) return q
        }
        return {}
      },
      getQuestionIndexById(id){
        let index = 0
        for(let q of this.questionList){
          if(q.id == id) return index
          index++
        }
        return null
      },
      showRuleQuestionList(action){
        return action == "jump-to" || action == "cond-show" || action == "cond-show-option"
      },
      showRuleValues(action){
        return action == "cond-show-option"
      },
      showQuestionByActionType(q,rule){
        let questionIndex = this.getQuestionIndexById(q.id)
        for(let c of rule.conditions){
          return !((c.qId == q.id) || (this.getQuestionIndexById(c.qId) > questionIndex))
        }
        if(rule.action.type == "cond-show-option" || rule.action.type == "cond-show"){
          return q.values && q.values.length > 0
        }
        return true
      },
      isImageQuestion(questionId){
        if(questionId == null || questionId == undefined) return false
        return this.getQuestionById(questionId).type == 'IMAGE_CHOICE' || this.getQuestionById(questionId).type == 'MULTIPLEIMAGE_CHOICE'
      },
      isMatrixQuestion(questionId){
        if(questionId == null || questionId == undefined) return false
        return this.getQuestionById(questionId).type == 'MATRIX'
      },
      isMatrixQuestionRadioButton(questionId){
        if(questionId == null || questionId == undefined) return false
        let q = this.getQuestionById(questionId)
        return q.type == 'MATRIX' && q.matrixRadioButton
      },
      hasErrors(){
        let rules = this.rules
        for(let i in rules){
          if((rules[i].action.type == "jump-to" || rules[i].action.type == "cond-show" || rules[i].action.type == "cond-show-option") && rules[i].action.qId == null) this.errors.push("rulequestion-"+i)
          if(rules[i].action.type == "cond-show-option" && rules[i].action.valId == null) this.errors.push("ruleval-"+i)
          for(let i2 in rules[i].conditions){
            if(rules[i].conditions[i2].valId == null || (rules[i].conditions[i2].equality == "#" && rules[i].conditions[i2].valId == 0)) this.errors.push("rulecondition-"+i+"-"+i2)
          }
        }
        if(this.errors.length > 0) return true
      },
    }
  }
</script>

<style scoped lang="scss">
.NavigationRules{
  display: flex;
  font-size: 18px;
  font-family: Roboto condensed;
  flex-direction: column;
  &.general{
    font-size: 16px;
    .NavigationRules--Condition{
      display: flex;
      gap: 14px;
    }
    p{
      width: fit-content;
    }
    .operator{
      width: 100px;
    }
  }
  &--Wrapper{
    margin-top: 15px;
  }
  &--Condition{
    align-items: center;
    margin-bottom: 14px;
    display: grid;
    grid-gap: 14px;
    grid-template-columns: 110px auto auto 30px;
    &.isMatrix{
      grid-template-columns: 110px auto auto auto 30px;
      select:nth-of-type(1){
        order:2;
      }
      select:nth-of-type(2){
        order:5;
      }
      select:nth-of-type(3){
        order:4;
      }
      select:nth-of-type(4){
        order:6 ;
      }
      p:nth-of-type(1){
        order:1;
      }

      p:nth-of-type(2){
        order: 3;
      }
      .condition-remove{
        order: 7;
      }
    }
  }
  &--AddCondition{
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin: 28px 0;
  }
  &--AddConditionIcon{
    background: #F7F4F8;
    border: 1px solid var(--accent-color);
    border-radius: 50px;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: calc(50% - 26px);
    cursor: pointer;
    &:hover:after{
      font-size: 16px;
      width: 120px;
      background-color: black;
      color: #fff;
      text-align: center;
      border-radius: 6px;
      padding: 5px 0;
      position: absolute;
      z-index: 1;
      bottom: 150%;
      left: 50%;
      margin-left: -60px;
      content: attr(data-title);
    }
  }
  &--AddConditionLine{
    width: 100%;
    border-top: 1px solid #E0E0E0;
  }
  &--Action{
    align-items: center;
    margin-bottom: 14px;
    display: grid;
    grid-gap: 14px;
    grid-template-columns: 65px auto auto;
  }
  &--ActionText{
    position: relative;
    font-size: 13px;
    letter-spacing: 0.01em;
    color: #96C800;
    text-transform: uppercase;
    border-left: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    padding: 20px 0 5px 9px;
    &::before{
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-top: 1px solid #E0E0E0;
      bottom: -5px;
      right: 2px;
      transform: rotate(45deg);
    }
    &::after{
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-top: 1px solid #E0E0E0;
      bottom: -6px;
      right: 2px;
      transform: rotate(135deg);
    }
  }
  p{
    width:100%;
  }
  select{
    font-family: Roboto condensed;
    padding: 10px;
    width: 100%;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
    outline: none;
    min-width: 50px;
  }
  .add-button{
    display: flex;
    font-family: Roboto Condensed;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    letter-spacing: -0.01em;
    color: #96C800;
    background: none;
    border: 0px solid;
    cursor: pointer;
    outline: none;
    div{
      margin-right: 7px;
    }
  }
}
input.error, select.error{
  border: 1px solid #d9534f!important;
}
.condition-remove{
  margin-bottom:0!important;
}
.Separator{
  width: 33%;
  margin: 30px auto 30px auto;
  border-top: 1px solid #E0E0E0;
  width: 100%;
}
.cond-show-option{
  grid-template-columns: 65px 1fr 1fr auto 1fr;
}
</style>
