<template>
<div class="QuestionEdit">
  <div class="QuestionEdit--Top">
    <div class="QuestionEdit--Info">
      <div class="QuestionEdit--Num">
        {{question.order}}
      </div>
      <div class="QuestionEdit--Icon">
        <div class="textQuestionsFakeInput" v-if="question.type == 'TEXT'">
          <p>Respue</p>
        </div>
        <div class="textQuestionsFakeInput textQuestionsFakeInput--textarea" v-if="question.type == 'TEXTAREA'">
          <p>Respue</p>
        </div>
        <div v-if="question.type == 'MULTITEXT'" style="display:flex;flex-direction:column;">
          <div class="textQuestionsFakeInput textQuestionsFakeInput--multitext">
            <p>Respue</p>
          </div>
          <div class="textQuestionsFakeInput textQuestionsFakeInput--multitext">
            <p>Respue</p>
          </div>
        </div>
        <QuestionSVG v-if="question.type == 'MATRIX' && question.matrixRadioButton" name="matrix-options" />
        <QuestionSVG v-if="question.type != 'RANKING' && !isTextQuestion() && !question.matrixRadioButton" :name="question.type" />
        <QuestionSVG v-if="question.type == 'RANKING' && !isTextQuestion()" :name="question.type+'-'+question.rankingType" />
      </div>
    </div>
    <div class="QuestionEdit--Content">
      <CommunityInput ref="QuestionText" class="QuestionEdit--Title" type="text" :required="true" :placeholder="$t('activity_survey_question_title')" v-model="question.text" :disableSuccessClass="true" v-on:key-up="$parent.dataIsUpdated = true"/>
      <div class="QuestionEdit--option" v-if="isQuestionRanking() && !isPollActivity">
        <div class="QuestionEdit--Labels">
          <input type="text" v-model="question.rankingLeftLabel" :placeholder="$t('activity_survey_question_label_left')" @keyup="$parent.dataIsUpdated = true">
          <input type="text" v-model="question.rankingRightLabel" :placeholder="$t('activity_survey_question_label_rigth')"  @keyup="$parent.dataIsUpdated = true">
        </div>
      </div>
      <div class="QuestionEdit--option" v-if="isQuestionRanking() || (question.type == 'MATRIX' && !question.matrixRadioButton)">
        <div class="QuestionEdit--FormWrap">
          <div class="QuestionEdit--FormGroup">
            <label>{{$t('activity_survey_min_valoration')}}</label>
            <input @change="$parent.dataIsUpdated = true" type="number" v-model="question.rankingMin" :class="{'error':errors.includes('rankingMin')}">
          </div>
          <div class="QuestionEdit--FormGroup">
            <label>{{$t('activity_survey_max_valoration')}}</label>
            <input @change="$parent.dataIsUpdated = true" type="number" v-model="question.rankingMax" :class="{'error':errors.includes('rankingMax')}">
          </div>
        </div>
      </div>
      <div class="QuestionEdit--option" v-if="isMultiSelection() && !isPollActivity">
        <div class="QuestionEdit--FormWrap">
          <div class="QuestionEdit--FormGroup">
            <label>{{$t('activity_survey_min_selection')}}</label>
            <select v-model="question.multiselectionMin" @change="$parent.dataIsUpdated = true">
              <option v-for="x in question.values.length" :value="x">{{x}}</option>
            </select>
          </div>
          <div class="QuestionEdit--FormGroup">
            <label>{{$t('activity_survey_max_selection')}}</label>
            <select v-model="question.multiselectionMax" @change="$parent.dataIsUpdated = true">
              <option v-for="x in question.values.length" :value="x">{{x}}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="QuestionEdit--option" v-if="question.type == 'DIVIDE_POINTS'">
        <div class="QuestionEdit--FormGroup">
          <label>{{$t('activity_survey_divide_points_to_distribute')}}</label>
          <input @change="$parent.dataIsUpdated = true" type="number" v-model="question.pointsNum" :class="{'error':errors.includes('pointsNum')}">
        </div>
      </div>
      <div class="QuestionEdit--option" v-if="question.type == 'MULTITEXT' || question.type == 'MULTINUMBER'">
        <div class="QuestionEdit--FormGroup">
          <label>{{$t('activity_survey_placeholders')}}</label>
          <input @keyup="$parent.dataIsUpdated = true" type="text" v-model="question.placeholders">
        </div>
      </div>
      <div class="QuestionEdit--option" v-if="question.type == 'MULTITEXT' || question.type == 'MULTINUMBER'">
        <div class="QuestionEdit--FormGroup">
          <label>{{$t('activity_survey_description_texts')}}</label>
          <input @keyup="$parent.dataIsUpdated = true" type="text" v-model="question.textDescriptions">
        </div>
      </div>
      <div class="QuestionEdit--option" v-if="question.type == 'MULTITEXT' || question.type == 'MULTINUMBER'">
        <div class="QuestionEdit--FormGroup">
          <label>{{$t('activity_survey_texts_number')}}</label>
          <input @change="$parent.dataIsUpdated = true" type="number" min="1" v-model="question.textNum" :class="{'error':errors.includes('textNum')}">
        </div>
      </div>
      <div class="QuestionEdit--option" v-if="isNumberQuestion()">
        <div class="QuestionEdit--FormGroup">
          <label>{{$t('activity_survey_magnitude')}}</label>
          <input @keyup="$parent.dataIsUpdated = true" type="text" v-model="question.units" :placeholder="$t('activity_survey_question_magnitude_example')">
        </div>
      </div>
      <div class="QuestionEdit--option" v-if="isNumberQuestion()">
        <div class="QuestionEdit--FormWrap">
          <div class="QuestionEdit--FormGroup">
            <label>{{$t('activity_survey_digits')}}</label>
            <input @change="$parent.dataIsUpdated = true" type="number" v-model="question.digitsNum" :class="{'error':errors.includes('digitsNum')}">
          </div>
          <div class="QuestionEdit--FormGroup">
            <label>{{$t('activity_survey_decimals')}}</label>
            <input @change="$parent.dataIsUpdated = true" type="number" v-model="question.decimalNum" :class="{'error':errors.includes('decimalNum')}">
          </div>
        </div>
      </div>
      <div class="QuestionEdit__Values" v-if="isQuestionWithValues()" :key="valuesKey">
        <draggable class="ActivityCreation__QuestionDrag" v-model="question.values" item-key="id" v-bind="dragOptions">
          <template #item="{element,index}">
            <div class="QuestionEdit__Values--Value" v-if="question.type != 'IMAGE_CHOICE' && question.type != 'MULTIPLEIMAGE_CHOICE'">
              <activitySVG :name="getValueSelectorName()" />
              <CommunityInput :ref="'QuestionValue-'+index" type="text" :required="true" :placeholder="question.type == 'MATRIX' ? $t('activity_survey_placeholder_element') :$t('activity_survey_placeholder_answer')" v-model="element.value" :disableSuccessClass="true" v-on:key-up="$parent.dataIsUpdated = true"/>
              <p v-on:click="removeValue(element)">{{$t('action_delete')}}</p>
              <i v-if="element.addtxt" class="fas fa-align-left"></i>
              <i style="cursor:pointer" class="fas fa-arrows-alt"></i>
            </div>
          </template>
        </draggable>
        <div class="QuestionEdit__Values--ImageValue" v-for="(v,i) in question.imageValues" v-if="question.type == 'IMAGE_CHOICE' || question.type == 'MULTIPLEIMAGE_CHOICE'">
          <activitySVG :name="getValueSelectorName()" />
          <div class="QuestionEdit__Values--Image" v-if="v.image == ''" :class="{'error':errors.includes('image-'+v.id)}">
            {{$t('image_upload')}}
          </div>
          <div class="QuestionEdit__Values--Image" v-if="v.image != ''" :style="{'backgroundImage':'url('+v.image+')'}"></div>
          <p v-on:click="removeImageValue(v)">{{$t('action_delete')}}</p>
          <Dropzone
            class="QuestionEdit--Uploader"
            ref="imageValueUploader"
            :uploadUrl="fileUploadPath"
            :uploadHeaders="uploadHeaders"
            :uploadText="''"
            :acceptedFiles="'image/jpg,image/jpeg,image/png'"
            :maxFiles="1"
            v-on:file-uploaded="imageValueUploadSuccess($event,i,'imageValues','image')" />
        </div>
        <div class="QuestionEdit__Values--Actions">
          <button class="add-button" type="button" name="button" v-on:click="addValue(null)" v-if="question.type != 'MATRIX'">
            <activitySVG :name="'plus-icon'"/>
            {{$t('activity_survey_new_answer')}}
          </button>
          <button class="add-button" type="button" name="button" v-on:click="addValue(null)" v-if="question.type == 'MATRIX'">
            <activitySVG :name="'plus-icon'"/>
            {{$t('activity_survey_new_element')}}
          </button>
          <button class="add-button" type="button" name="button" v-on:click="addValue('addtxt')" v-if="isAddtxtOptionEnabled()">
            <activitySVG :name="'plus-icon'" />
            {{$t('activity_survey_new_option')}}
          </button>
        </div>
        <div class="QuestionEdit__Values--Value" v-for="(v,i) in question.radioOptionTexts" v-if="question.type == 'MATRIX' && question.matrixRadioButton">
          <activitySVG :name="getValueSelectorName()" />
          <CommunityInput :ref="'QuestionValueRadio-'+i" type="text" :required="true" :placeholder="$t('activity_survey_placeholder_answer')" v-model="question.radioOptionTexts[i]" :disableSuccessClass="true" v-on:key-up="$parent.dataIsUpdated = true"/>
          <p v-on:click="removeRadioValue(i)">{{$t('action_delete')}}</p>
        </div>
        <div class="QuestionEdit__Values--Actions" v-if="question.type == 'MATRIX' && question.matrixRadioButton">
          <button class="add-button" type="button" name="button" v-on:click="addRadioValue()">
            <activitySVG :name="'plus-icon'"/>
            {{$t('activity_survey_new_answer')}}
          </button>
        </div>
      </div>
    </div>
    <div class="QuestionEdit--Actions" v-if="!isPollActivity">
      <activitySVG class="duplicateTooltip" :name="'duplicate-icon'" v-on:click.native="$emit('duplicate')" :data-title="$t('action_duplicate')"/>
      <activitySVG :name="'remove-icon'" v-on:click.native="$emit('remove')" />
      <i class="fas fa-arrows-alt"></i>
    </div>
  </div>
  <div class="QuestionEdit--Bottom" v-if="!isPollActivity">
    <div class="QuestionEdit__Config">
      <div class="QuestionEdit__Config--Options">
        <button type="button" name="button" :class="{'active':showConfiguration}" v-on:click="showConfiguration=!showConfiguration;showRules=false">
          {{$t('new_activity_survey_configuration')}}
          <activitySVG :name="'arrow-down'"/>
        </button>
        <button type="button" name="button" :class="{'active':showRules}" v-on:click="showRules=!showRules;showConfiguration=false">
          {{$t('new_activity_survey_logic')}}
          <activitySVG :name="'arrow-down'"/>
        </button>
      </div>
    </div>
    <transition name="list">
      <div class="QuestionEdit__Configuration" v-show="showConfiguration">
        <SwitchInput v-model="question.rdmAnswers" :text="$t('activity_question_random_answers')" v-if="isQuestionRandom()"/>
        <SwitchInput v-model="question.textEmpty" :text="$t('activity_question_empty_asnwer')" v-if="(isTextQuestion() && question.type != 'MULTITEXT') || question.type == 'IMAGE_URL'"/>
        <SwitchInput v-model="question.textsRequired" :text="$t('activity_question_required_asnwer')" v-if="question.type == 'MULTITEXT'"/>
        <SwitchInput v-model="question.notResponse" :text="$t('activity_question_ns_nc')" v-if="isTextQuestion()"/>
        <input class="QuestionEdit--notResponse" type="text" v-model="question.notResponseText" v-if="isTextQuestion() && question.notResponse" :placeholder="$t('activity_question_ns_nc_text')" @keyup="$parent.dataIsUpdated = true">
        <div class="QuestionEdit--Media">
          <activitySVG :name="'image-uploader'"/>
          <p>{{$t('activity_survey_add_image')}}</p>
          <Dropzone
            class="QuestionEdit--Uploader"
            ref="imageUploader"
            :uploadUrl="fileUploadPath"
            :uploadHeaders="uploadHeaders"
            :uploadText="''"
            :acceptedFiles="'image/jpg,image/jpeg,image/png'"
            :maxFiles="1"
            v-on:file-uploaded="imageUploadSuccess" />
        </div>
        <div class="QuestionEdit--FileList" :key="fileImageKey" v-show="question.images!=undefined && question.images.length > 0">
          <div data-content="Eliminar" v-on:click="deleteImage(i)" :style="{'backgroundImage':'url('+img+')'}" v-for="(img,i) in question.images">
          </div>
        </div>
        <div class="QuestionEdit--Media">
          <activitySVG :name="'video-uploader'" />
          <p>{{$t('activity_survey_add_video')}}</p>
          <Dropzone
            class="QuestionEdit--Uploader"
            ref="videoUploader"
            :uploadUrl="videoUploadPath"
            :uploadHeaders="uploadHeaders"
            :uploadText="''"
            :acceptedFiles="'video/*'"
            :maxFiles="1"
            v-on:file-uploaded="videoUploadSuccess" />
        </div>
        <div class="QuestionEdit--FileList" :key="videoKey">
          <div data-content="Eliminar" v-on:click="deleteVideo()" v-if="question.video!=undefined">
            <video>
              <source :src="question.video" type="video/mp4">
              <source :src="question.video" type="video/ogg">
            </video>
          </div>
        </div>
      </div>
    </transition>
    <transition name="list">
      <NavigationRules ref="navigationRules" :rules="getRulesForCurrentQuestion()" :questionList="questionList" :question="question" v-on:add-rule="$emit('add-rule')" v-if="showRules"/>
    </transition>
  </div>
</div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import activitySVG from '~/components/ActivitySVG/activitySVG.vue'
  import QuestionSVG from '~/components/Question/QuestionSVG'
  import SwitchInput from '~/components/SwitchInput/SwitchInput'
  import Dropzone from '~/components/Dropzone';
  import CommunityInput from '@/components/CommunityInput.vue';

  import NavigationRules from '~/components/SurveyCreation/NavigationRules.vue';
  import { isEmptyText, sameDayDates, isYouTubeVideo } from '@/utils';
  import draggable from 'vuedraggable'

  export default {
    name: 'QuestionEdit',

    components:{
      activitySVG,
      QuestionSVG,
      SwitchInput,
      Dropzone,
      CommunityInput,
      NavigationRules,
      draggable
    },
    props: {
      question:{
        type:Object,
      },
      questionList:{
        type:Array,
      },
      rules:{
        type: Array,
      },
      fileUploadPath: String,
      videoUploadPath: String,
      uploadHeaders:{
        type: Object,
        default: null,
      },
      isPollActivity:{
        type: Boolean,
        default: false,
      }
    },
    computed: {
      dragOptions() {
        return {
          animation: 200,
          group: "description",
          disabled: false,
          ghostClass: "ghost"
        };
      }
    },
    data(){
      return{
        valuesKey:0,
        videoKey:0,
        fileImageKey:100,
        showConfiguration:false,
        showRules:false,
        errors:[],
      }
    },
    methods:{
      getRulesForCurrentQuestion(){
        if(this.rules.length == 0) return []
        let rules = []
        let rule = undefined
        for(let r of this.rules){
          if(r.conditions[0].qId == this.question.id) rule = r
          for(let c of r.conditions){
            if(c.qId != this.question.id){
              rule = undefined
              break;
            }
          }
          if(rule != undefined) rules.push(rule)
        }
        return rules
      },
      getValueSelectorName(){
        let name = ""
        switch (this.question.type) {
          case "SELECTION":
          case "IMAGE_CHOICE":
          case "MATRIX":
          case "DIVIDE_POINTS":
          case "SORTING":
            name = "SELECTION-selector"
            break;
          case "MULTISELECTION":
          case "MULTIPLEIMAGE_CHOICE":
            name = "MULTISELECTION-selector"
            break;
        }
        return name
      },
      getQuestionById: function(id){
        for(let q of this.questionList){
          if(q.id == id) return q
        }
      },
      isAddtxtOptionEnabled(){
        return (this.question.type == "SELECTION" || this.question.type == 'MULTISELECTION') && !this.isPollActivity
      },
      isNumberQuestion(){
        return this.question.type == "NUMBER" || this.question.type == "DECIMAL_NUMBER" || this.question.type == "MULTINUMBER"
      },
      isMultiSelection(){
        return this.question.type == "MULTISELECTION" || this.question.type == "MULTIPLEIMAGE_CHOICE"
      },
      isQuestionRanking(){
        return this.question.type == "RANKING" || this.question.type == "RATING_FACES"
      },
      isQuestionRandom(){
        return this.isQuestionWithValues() && this.question.type != "DIVIDE_POINTS"
      },
      isTextQuestion(){
        let valid = false
        switch (this.question.type) {
          case "TEXT":
          case "MULTITEXT":
          case "TEXTAREA":
            valid = true
            break;
        }
        return valid
      },
      isQuestionWithValues(type){
        let t = type != null && type != undefined ? type : this.question.type
        let valid = false
        switch (t) {
          case "SELECTION":
          case "MULTISELECTION":
          case "SORTING":
          case "MATRIX":
          case "IMAGE_CHOICE":
          case "MULTIPLEIMAGE_CHOICE":
          case "DIVIDE_POINTS":
            valid = true
            break;
        }
        return valid
      },
      getValueMaxId(){
        let id = 0
        for(let v in this.question.values) {
          if(parseInt(v.id) > id) id = parseInt(v.id)
        }
        return id + 1
      },
      addImageValue(){
        if(this.question.imageValues == undefined) this.question.imageValues = []
        let value = {
          id:this.question.imageValues.length,
          image:'',
          trap: false,
        }
        this.question.imageValues.push(value)
        this.valuesKey++
        this.$parent.dataIsUpdated = true
      },
      addRadioValue(){
        if(this.question.radioOptionTexts == undefined) this.question.radioOptionTexts = []
        this.question.radioOptionTexts.push("")
        this.valuesKey++
        this.$parent.dataIsUpdated = true
      },
      removeRadioValue(index){
        this.question.radioOptionTexts.splice(index,1)
        this.valuesKey++
        this.$parent.dataIsUpdated = true
      },
      addValue(option){
        if(this.question.type == 'IMAGE_CHOICE' || this.question.type == 'MULTIPLEIMAGE_CHOICE') return this.addImageValue()
        if(this.question.values == undefined) this.question.values = []
        let value = {
          id:this.question.values.length,
          value:'',
          addtxt:option=="addtxt" ? true : false,
          trap:false
        }
        this.question.values.push(value)
        this.valuesKey++
        this.$parent.dataIsUpdated = true
      },
      removeValue(value){
        for(let i in this.question.values){
          if(this.question.values[i].id == value.id) this.question.values.splice(i,1)
        }
        this.valuesKey++
        this.$parent.dataIsUpdated = true
      },
      removeImageValue(value){
        for(let i in this.question.imageValues){
          if(this.question.imageValues[i].id == value.id) this.question.imageValues.splice(i,1)
        }
        this.valuesKey++
        this.$parent.dataIsUpdated = true
      },
      imageValueUploadSuccess(ev,index,values,value){
        this.question[values][index][value] = ev.data.path
        this.$refs.imageValueUploader[index].reset()
        this.valuesKey++
        this.$parent.dataIsUpdated = true
      },
      imageUploadSuccess(file, response){
        if(this.question.images == undefined || this.question.images == null) this.question.images = []
        this.question.images.push(response.path)
        this.$refs.imageUploader.reset()
        this.fileImageKey++
        this.$parent.dataIsUpdated = true
      },
      videoUploadSuccess(file, response){
        this.question.video = response.path
        this.$refs.videoUploader.reset()
        this.videoKey++
        this.$parent.dataIsUpdated = true
      },
      deleteImage(i){
        this.question.images.splice(i,1)
        this.fileImageKey++
        this.$parent.dataIsUpdated = true
      },
      deleteVideo(i){
        this.question.video = undefined
        this.videoKey++
        this.$parent.dataIsUpdated = true
      },
      addCondition(index){
        let operator = this.rules[index].conditions.length > 0 ? "&" : null
        this.rules[index].conditions[this.rules[index].conditions.length-1].operator = operator
        this.rules[index].conditions.push({equality:"=",operator:null,taskNum:1,qId:this.question.id,valId:0})
        this.$parent.dataIsUpdated = true
      },
      removeCondition(ruleIndex,conditionIndex){
        this.rules[ruleIndex].conditions.splice(conditionIndex,1)
        if(this.rules[ruleIndex].conditions.length == 0) this.rules.splice(ruleIndex,1)
        this.$parent.dataIsUpdated = true
      },
      showRuleQuestionList(action){
        return action == "jump-to" || action == "cond-show" || action == "cond-show-option"
      },
      showRuleValues(action){
        return action == "cond-show-option"
      },
      changeConditionOperator(index, operator){
        for(let c of this.rules[index].conditions){
          if(c.operator != null) c.operator = operator
        }
        this.$parent.dataIsUpdated = true
      },
      hasErrors(){
        this.errors = []
        if(this.$refs.QuestionText.validate()) this.errors.push("text")
        if(this.isQuestionWithValues()){
          let values = this.question.type == "IMAGE_CHOICE" || this.question.type == 'MULTIPLEIMAGE_CHOICE' ? "imageValues" : "values"
          let value = this.question.type == "IMAGE_CHOICE" || this.question.type == 'MULTIPLEIMAGE_CHOICE' ? "image" : "value"
          if(this.question[values] == undefined || this.question[values].length == 0) this.errors.push(values)
          for(let i in this.question[values]){
            if(this.question.type == 'IMAGE_CHOICE' || this.question.type == 'MULTIPLEIMAGE_CHOICE'){
              if(this.question[values][i][value] == "") this.errors.push(value+"-"+this.question[values][i].id)
            }
            else{
              if(this.$refs['QuestionValue-'+i].validate()) this.errors.push(value+"-"+this.question[values][i].id)
            }
          }
        }
        if(this.question.type == "DIVIDE_POINTS" && this.question.pointsNum < 1) this.errors.push("pointsNum")
        if((this.question.type == 'MULTITEXT' || this.question.type == 'MULTINUMBER') && this.question.textNum < 1) this.errors.push("textNum")
        if(this.isNumberQuestion() && (this.question.digitsNum < 0 && this.question.decimalNum <0)) this.errors.push("digitsNum")
        if(this.isQuestionRanking() || (this.question.type == "MATRIX" && !this.question.matrixRadioButtons)){
          if(this.question.rankingMin < 0) this.errors.push("rankingMin")
          if(this.question.rankingMax < 2) this.errors.push("rankingMax")
          if(parseInt(this.question.rankingMax) <= parseInt(this.question.rankingMin)){
            this.errors.push("rankingMin")
            this.errors.push("rankingMax")
          }
        }
        if(this.question.type == "MATRIX" && this.question.matrixRadioButtons){
          if(this.question.radioOptionTexts.length == 0) this.errors.push("radioOptionTexts")
          for(let i in this.question.radioOptionTexts){
            if(this.$refs['QuestionValueRadio-'+i].validate()) this.errors.push("radioOptionTexts"+"-"+i)
          }
        }
        if(this.$refs.navigationRules != undefined) this.$refs.navigationRules.hasErrors()
        if(this.errors.length > 0) return true
      },
    },

  }
</script>

<style lang="scss">
.QuestionEdit{
  &--Title{
    input{
      border: none!important;
      background: none!important;
      height: 53px;
      font-size: 32px!important;
      color: #3E3E3E;
      padding: 9px 0 11px 0px;
      &:placeholder-shown, &:focus{
        color: #969696!important;
        background: #F5F5F5!important;
        border: 1px solid #D3D3D3!important;
        padding: 9px 0 11px 20px!important;
      }
    }
  }
  &__Values--Value .CommunityInput{
    width: 100%;
    margin: 0!important;
  }
  &__Values--Value .CommunityInput--Input{
    border: none!important;
    background: none!important;
    color: #3E3E3E;
    font-size: 22px!important;
    height: 40px;
    padding: 8px 80px 8px 0px;
    z-index:0;
    &:placeholder-shown, &:focus{
      color: #969696!important;
      background: #F5F5F5!important;
      border: 1px solid #D3D3D3!important;
      padding: 8px 80px 8px 16px!important;
      z-index:1!important;
    }
  }
}
</style>
<style scoped lang="scss">
.duplicateTooltip{
  position:relative;
  &:hover:after{
    font-size: 16px;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -60px;
    content: attr(data-title);
  }
}
.QuestionEdit{
  width:100%;
  background: #FFFFFF;
  box-shadow: 3px -4px 22px rgba(0, 0, 0, 0.15);
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  &--Top{
    width: 100%;
    display: flex;
    padding-top: 20px;
    margin-left: -19px;
  }
  &--Num{
    width: 80px;
    height: 80px;
    background: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    border: 2px solid #FFFFFF;
    box-shadow: 1px 2px 16px rgba(0, 0, 0, 0.11);
    color: white;
    font-weight: bold;
    font-size: 49px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
  }
  &--Icon{
    width: 80px;
    height: 67px;
    left: 95px;
    top: 615px;
    background: #FFFFFF;
    border: 1px solid #E1E1E1;
    display: flex;
    align-items: center;
    justify-content: center;
    filter: drop-shadow(3px 3px 1px rgba(0, 0, 0, 0.08));
    div{
      width: 90%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    svg{
      max-width: 100%;
    }
  }
  &--Actions{
    margin-top: 13px;
    div{
      margin-bottom: 19px;
      cursor: pointer;
    }
    i{
      cursor:pointer;
      font-size: 23px;
      color: #BDBDBD;
      margin-bottom: 19px;
    }
  }
  &--Content{
    width: 100%;
    margin-right: 20px;
    margin-left: 32px;
    margin-top: 13px;
  }
  &__Values{
    margin-top: 16px;
    &--Value{
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      position: relative;
      div{
        margin: 0 20px 0 12px;
      }
      p{
        font-size: 16px;
        letter-spacing: -0.01em;
        color: #96C800;
        position: absolute;
        right: 0;
        padding: 0 20px 0 0;
        z-index:0;
        cursor: pointer;
      }
      i{
        font-size: 16px;
        color: #96C800;
        position: absolute;
        right: 89px;
        z-index:0;
        &.fa-arrows-alt{
          cursor: pointer;
        }
      }
    }
    &--ImageValue{
      display: flex;
      align-items: center;
      margin-bottom: 5px;
      position: relative;
      p{
        margin-left:20px;
        font-size: 16px;
        letter-spacing: -0.01em;
        color: #96C800;
        cursor: pointer;
      }
      div:first-of-type{
        margin: 0 20px 0 12px;
      }
      .QuestionEdit--Uploader{
        height: 80px;
        width: 100px;
        left: 58px;
      }
    }
    &--Image{
      height: 80px;
      width: 100px;
      min-height: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center;
      background-size: contain;
      color: #969696;
      background: #F5F5F5;
      border: 1px solid #D3D3D3;
    }
    &--Actions{
      display: flex;
      margin: 13px 0 13px 52px;
      button{
        margin-right: 20px;
      }
    }
  }
  &__Config{
    &--Options{
      display: flex;
      justify-content: flex-end;
      margin-right: 60px;
      button{
        background: #F7F4F8;
        font-size: 17px;
        letter-spacing: -0.01em;
        color: #3E3E3E;
        border: none;
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: normal;
        margin-left: 5px;
        padding: 10px 20px;
        outline: none;
        display: flex;
        cursor: pointer;
        &.active{
          box-shadow: 0px 18px 6px rgb(255, 255, 255);
          background: #FFFFFF;
          border-top: 1px solid #E8E8E8;
          border-left: 1px solid #E8E8E8;
          border-right: 1px solid #E8E8E8;
          // box-shadow: 3px -4px 22px rgba(0, 0, 0, 0.15);

          div{
            transform: rotate(360deg);
            transition:0.5s;
            margin:0px 0 0 5px;
          }
        }
        div{
          transform: rotate(180deg);
          transition:0.5s;
          margin:3px 0 0 5px;
          padding-bottom:1px;
        }
      }
    }
  }
  &__Configuration{
    display: flex;
    padding: 20px 92px;
    flex-wrap:wrap;
    box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.08);
  }
  .NavigationRules{
    font-size: 18px;
    font-family: Roboto condensed;
    flex-direction: column;
    padding: 20px 92px;
    flex-wrap:wrap;
    box-shadow: inset 0px 4px 11px rgba(0, 0, 0, 0.08);
  }
  &--Media{
    width: 100%;
    display: flex;
    align-items: center;
    position:relative;
    margin-top: 10px;
    p{
      margin-left: 13px;
      font-size: 19px;
    }
  }
  &--Uploader{
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    border: none;
    &:hover{
      background: transparent;
    }
  }
  &--FileList{
    width: 100%;
    display: flex;
    margin-top: 20px;
    video{
      width: 100%;
      height: 100%;
    }
    div{
      width: 100px;
      height: 90px;
      border-radius: 6px;
      box-shadow: 3px -4px 22px rgba(0, 0, 0, 0.15);
      background-position: center;
      background-size: contain;
      margin-right: 10px;
      position: relative;
      cursor: pointer;
      &:hover{
        background-color: rgba(0,0,0,0.5);
      }
      &:hover:after{
        font-family: Roboto Condensed;
        font-style: normal;
        font-weight: normal;
        position: absolute;
        content: attr(data-content);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 6px;
        background: rgba(0,0,0,0.5);
        color: white;
        top: 0;
      }
    }
  }
  &--FormWrap{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fill, calc( 50% - 5px));
    grid-gap: 10px;
  }
  &--FormGroup{
    display: flex;
    flex-direction: column;
    select,input{
      width: 100%;
      outline: none;
      background: #F5F5F5;
      border: 1px solid #D3D3D3;
      color: #969696;
      padding: 8px 0 8px 16px;
      font-size: 19px;
      letter-spacing: 0.01em;
      height: 40px;
    }
  }
  &--notResponse{
    font-size: 19px;
    letter-spacing: 0.01em;
    height: 40px;
    outline: none;
    color: #969696;
    background: #F5F5F5;
    border: 1px solid #D3D3D3;
    padding: 8px 0 8px 16px;
  }
  &--Labels{
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
    input{
      margin-bottom: 5px;
      color: #969696;
      background: #F5F5F5;
      border: 1px solid #D3D3D3;
      padding: 8px 0 8px 16px;
      outline: none;
      font-size: 22px;
      letter-spacing: 0.01em;
      height: 40px;
    }
  }
  &--option{
    margin: 16px 0 0 12px;
  }
}
.textQuestionsFakeInput{
  width:88px;
  height:28px;
  background: #E3E3E3;
  margin: 15px 0;
  p{
    text-transform: capitalize;
    font-weight: bold;
    border-right: 1px solid #3E3E3E;
    width: fit-content;
    font-size: 18px;
    margin: 4px;
    padding-right: 1px;
    background-image: linear-gradient(90deg, #FF1564 0%, #FD7328 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &--textarea{
    height:33px;
    p{
      font-size:10px;
    }
  }
  &--multitext{
    height: 17px;
    &:nth-of-type(1){
      margin: 0px 0 4px 0;
    }
    &:nth-of-type(2){
      margin: 4px 0 0px 0;
    }
    p{
      font-size: 13px;
      margin:1px 4px;
    }
  }
}
.SwitchInput{
  margin: 10px 15px 10px 0;
}
input.error, select.error{
  border: 1px solid #d9534f!important;
}
.add-button{
  display: flex;
  font-family: Roboto Condensed;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: -0.01em;
  color: #96C800;
  background: none;
  border: 0px solid;
  cursor: pointer;
  outline: none;
  div{
    margin-right: 7px;
  }
}
.list-enter,
.list-leave-to {
  visibility: hidden;
  height: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  // opacity: 0;
}
.list-enter-active,
.list-leave-active {
  transition: 0.1s;
}
</style>
