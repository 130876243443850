import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, vShow as _vShow, withDirectives as _withDirectives } from "vue"
import _imports_0 from '../../assets/img/studyIcons/addImage.svg'
import _imports_1 from '../../assets/img/studyIcons/addVideo.svg'


const _hoisted_1 = { class: "QuestionConfig" }
const _hoisted_2 = { class: "QuestionConfig--MediaUpload" }
const _hoisted_3 = { class: "QuestionConfig--FileViewer" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = { class: "QuestionConfig--MediaUpload" }
const _hoisted_6 = { class: "QuestionConfig--FileViewer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SwitchInput = _resolveComponent("SwitchInput")!
  const _component_Dropzone = _resolveComponent("Dropzone")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isQuestionRandom())
      ? (_openBlock(), _createBlock(_component_SwitchInput, {
          key: 0,
          modelValue: _ctx.question.rdmAnswers,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.question.rdmAnswers) = $event)),
          text: _ctx.$t('activity_question_random_answers')
        }, null, 8, ["modelValue", "text"]))
      : _createCommentVNode("", true),
    ((_ctx.isTextQuestion() && _ctx.question.type != 'MULTITEXT') || _ctx.question.type == 'IMAGE_URL')
      ? (_openBlock(), _createBlock(_component_SwitchInput, {
          key: 1,
          modelValue: _ctx.question.textEmpty,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.question.textEmpty) = $event)),
          text: _ctx.$t('activity_question_empty_asnwer')
        }, null, 8, ["modelValue", "text"]))
      : _createCommentVNode("", true),
    (_ctx.question.type == 'MULTITEXT')
      ? (_openBlock(), _createBlock(_component_SwitchInput, {
          key: 2,
          modelValue: _ctx.question.textsRequired,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.question.textsRequired) = $event)),
          text: _ctx.$t('activity_question_required_asnwer')
        }, null, 8, ["modelValue", "text"]))
      : _createCommentVNode("", true),
    (_ctx.isTextQuestion())
      ? (_openBlock(), _createBlock(_component_SwitchInput, {
          key: 3,
          modelValue: _ctx.question.notResponse,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.question.notResponse) = $event)),
          text: _ctx.$t('activity_question_ns_nc')
        }, null, 8, ["modelValue", "text"]))
      : _createCommentVNode("", true),
    (_ctx.isTextQuestion() && _ctx.question.notResponse)
      ? (_openBlock(), _createBlock(_component_SwitchInput, {
          key: 4,
          class: "QuestionConfig--notResponse",
          type: "text",
          modelValue: _ctx.question.notResponseText,
          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.question.notResponseText) = $event)),
          placeholder: _ctx.$t('activity_question_ns_nc_text')
        }, null, 8, ["modelValue", "placeholder"]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("img", {
        src: _imports_0,
        alt: ""
      }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('activity_survey_add_image')), 1),
      _createVNode(_component_Dropzone, {
        class: "QuestionConfig--Dropzone",
        ref: "imageUploader",
        uploadUrl: _ctx.filePath,
        uploadHeaders: _ctx.headers,
        uploadText: '',
        acceptedFiles: 'image/jpg,image/jpeg,image/png',
        maxFiles: 1,
        onFileUploaded: _ctx.imageUploadSuccess
      }, null, 8, ["uploadUrl", "uploadHeaders", "onFileUploaded"]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.question.images, (src, index) => {
          return (_openBlock(), _createElementBlock("div", {
            onClick: ($event: any) => (_ctx.deleteImage(index)),
            style: _normalizeStyle({'backgroundImage':'url('+src+')'})
          }, _cache[6] || (_cache[6] = [
            _createElementVNode("span", null, "x", -1)
          ]), 12, _hoisted_4))
        }), 256))
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _cache[9] || (_cache[9] = _createElementVNode("img", {
        src: _imports_1,
        alt: ""
      }, null, -1)),
      _createElementVNode("p", null, _toDisplayString(_ctx.$t('activity_survey_add_video')), 1),
      _createVNode(_component_Dropzone, {
        class: "QuestionConfig--Dropzone",
        ref: "videoUploader",
        uploadUrl: _ctx.videoPath,
        uploadHeaders: _ctx.headers,
        uploadText: '',
        acceptedFiles: 'video/*',
        maxFiles: 1,
        onFileUploaded: _ctx.videoUploadSuccess
      }, null, 8, ["uploadUrl", "uploadHeaders", "onFileUploaded"]),
      _withDirectives(_createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", {
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.deleteVideo(_ctx.index))),
          style: _normalizeStyle({'backgroundImage':'url('+_ctx.question.video+')'})
        }, _cache[8] || (_cache[8] = [
          _createElementVNode("span", null, "x", -1)
        ]), 4)
      ], 512), [
        [_vShow, _ctx.question.video != undefined && _ctx.question.video.length > 0]
      ])
    ])
  ]))
}