
import { defineComponent } from 'vue';
export default defineComponent({
  name : 'Collapsable',
  props:{
    collapsed:{
      type: Boolean,
      default: false
    }
  }
})
